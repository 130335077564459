import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMinmax]',
})
export class MinmaxDirective {
  @Input()
  public min: number;

  @Input()
  public max: number | '';

  constructor(private ref: ElementRef) { }

  @HostListener('input', ['$event'])
  public onInput(a_Event: InputEvent): void {
    let val = parseInt(this.ref.nativeElement.value, 10);
    if (!isNaN(val)) {
      if (this.max !== '' && val > this.max) {
        this.ref.nativeElement.value = this.max.toString();
      } else if (!isNaN(this.min) && val < this.min) {
        this.ref.nativeElement.value = this.min.toString();
      }
    }
  }
}
