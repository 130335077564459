<div class="row mb-4 red titleBar">
    <div class="col-md-3 text-start"><a (click)="navigate()"><i class="fa fa-arrow-left"></i>
            &nbsp;&nbsp;{{'HOME.BACKBUTTON' | translate}}
        </a></div>
    <div class="col-md-9 text-end">
        {{'HOME.REDQUES' | translate}}
    </div>
</div>

<div class="row">
    <div class="col-md-6 mx-2" *ngFor="let items of categories">
        <div class="row mb-2  actionButton " (click)="setFormFields(items); $event.stopPropagation();">
            <div class="col my-auto text-start">
                <div class="row">
                    <span class="button-text-small" *ngIf="items.languageKey == null  || items.languageKey == ''">
                        {{items.text}}
                    </span>
                    <span class="button-text-small" *ngIf="items.languageKey != null && items.languageKey != ''">{{
                        "HOME."+items.languageKey | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popupformmodal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="popupform" (keyup.enter)="submitdropdown()" novalidate>
            <ng-container *ngFor="let j of firstIndexvalue;let k=index">
               <div *ngFor="let a of placeholder;let i=index">
                <div class="mb-3" *ngIf="j===a">
                    <div class="form-group">
                        <input type="text" class="form-control" [formControlName]="a" placeholder="{{a}}">
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="dropdown_values.length > 0 && j.includes(dropdown_values[k]) ">
                <select class="form-select form-select-lg" formControlName="mail" [(ngModel)]="defaultDropdown"
                    aria-label=".form-select-sm example">
                    <option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
                </select>
            </div>
            </ng-container>
           
            <div *ngIf="this.url">
                <a class="url" target=”_blank” href="{{this.url}}">{{ this.url}}</a>

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitdropdown()">{{'HOME.CONFIRMBUTTON' |
            translate}}</button>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn btn-success reset"
            (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
    </div>
</ng-template>