import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import Inputmask from "inputmask";
import { DataService } from 'src/services/dataservice.service';
import { StockBuffer } from "../../models/stockBuffer";
declare var $: any;

import { DeviceDetectorService } from 'ngx-device-detector';
import { Openorders } from 'src/models/order';
import { ordersResponse } from 'src/models/orders';
@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {

  @ViewChild('content') public content: TemplateRef<Object>;
  @ViewChild('number_input') searchElement: ElementRef;
  @ViewChild('submitButton') submitButton!: ElementRef;
  // project_Form: any;
  SearchText: string;
  submitted = false;
  form: UntypedFormGroup;
  orderlist: any;
  order_to_pass: any;
  workstationorders: ordersResponse[];
  src: string;
  closeResult: string;
  users: any = [];
  workstationid: string | null;
  current_user: any;
  role: string | null;
  input: any;
  input_item: any;
  input_mask: any;
  input_desc: string | null;
  HideSelectButton: boolean;
  customerID: string | null;
  subscription: any;
  customizing: any;
  productList: any;
  selectedProduct: any;
  sumOfQty: any;
  addedQty: any;
  isSumOfQty: boolean;
  productForm: UntypedFormGroup;
  stockBufferList: StockBuffer[] = []
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  isKeyboard: boolean = false;
  idleTimer: any;
  currentlang: string | null;
  modalInstance: any;
  orders_in_use: any;
  projectExtentionValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  constructor(private deviceService: DeviceDetectorService, private DataService: DataService, private LandingPageComponent: LandingPageComponent, private modalService: NgbModal, private toastrService: ToastrService, private apiservice: ApiService, public DashboardComponent: DashboardComponent, private router: Router, public fb: UntypedFormBuilder) {
    this.role = localStorage.getItem("role");
    this.users = localStorage.getItem("users");
    this.current_user = localStorage.getItem("current_user");
    (this.current_user) ? this.current_user = JSON.parse(this.current_user) : this.current_user = undefined;
    (this.users) ? this.users = JSON.parse(this.users) : this.users = undefined;
    this.workstationid = localStorage.getItem("workstationID");
    this.customerID = localStorage.getItem('customerID');
    this.currentlang = localStorage.getItem('language');

    this.getProductsByCustomerId();
    this.form = new UntypedFormGroup({
      order: new UntypedFormControl('', Validators.required),
      projectExtention: new UntypedFormControl('')
    });
    this.productForm = new UntypedFormGroup({
      qty: new UntypedFormControl('', Validators.required),
    });
    this.selectedProduct = undefined;
    this.isSumOfQty = false;
    this.addedQty = '';
    this.input_mask = localStorage.getItem("input_mask");
    this.input_desc = localStorage.getItem('mask_descp');

  }


  ngOnInit(): void {

    let openOrderss: any = JSON.parse(localStorage.getItem('openOrderss') || '[]');
    this.orderlist = openOrderss;
    this.DataService.currentMessage.subscribe((item: string) => {
      item === 'en'
        ? (this.currentlang = 'English')
        : item === 'du' ? (this.currentlang = 'Dutch') : (this.currentlang = 'Spanish');
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType != 'mobile') {
      this.isKeyboard = true
    };
    $(document).ready(function (ex: any) {
      $('.inputField').on("touchstart", function (event: any) {
        event.preventDefault();
        $('#testField').focus();
      });
    });

    setTimeout(() => {
      this.searchElement?.nativeElement.focus();
      if (this.input_mask != null && this.input_mask != undefined) {
        this.input = new Inputmask(this.input_mask).mask(this.searchElement.nativeElement);
      }
    }, 200);
    let flag = localStorage.getItem("openorderpopup");
    setTimeout(() => {
      (flag == "true") ? this.open(this.content) : '';
      localStorage.removeItem("openorderpopup");
    }, 300);
    // this.geterp();
  }
  ngDoCheck() {
    this.customizing = localStorage.getItem("customizing");
    (this.customizing) ? this.customizing = JSON.parse(this.customizing) : '';
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:click', ['$event'])
  onUserActivity() {
    this.resetTimer()
  }

  getProduct(product: any) {
    if (this.selectedProduct !== undefined && product.ProductID != this.selectedProduct.ProductID) {
      this.productForm.reset()
      this.isSumOfQty = false
      this.addedQty = ''
    }
    let temp = (window.event as any).srcElement.className;
    let inputValue = !isNaN(parseInt(this.productForm.value.qty)) ? parseInt(this.productForm.value.qty) : 0;

    if (temp != 'btn btn-primary') {
      this.selectedProduct = product;
      this.sumOfQty = parseInt(product.inventory) + inputValue;
    }
  }

  sum() {
    if (this.productForm.value.qty.trim() == '' || this.productForm.value.qty == '-') {
      this.sumOfQty = this.selectedProduct.inventory;
    } else {
      this.sumOfQty = parseInt(this.selectedProduct.inventory) + parseInt(this.productForm.value.qty);
    }
  }

  sumStockBuffer(stockBuffer: StockBuffer) {

    if (this.productForm.value.qty == null || this.productForm.value.qty.trim() == '' || this.productForm.value.qty == '-') {
      this.sumOfQty = this.selectedProduct.inventory;
      this.stockBufferList[stockBuffer.index].sumOfQty = this.selectedProduct.inventory;
    } else {
      this.sumOfQty = parseInt(this.selectedProduct.inventory) + parseInt(this.productForm.value.qty);
      this.stockBufferList[stockBuffer.index].sumOfQty = this.sumOfQty
      this.isSumOfQty = true
    }
  }

  updateQuantity() {

    if (this.sumOfQty != '') {
      let payload = {
        "userID": this.current_user.userID,
        "productID": this.selectedProduct.ProductID,
        "field": "inventory",
        "value": this.sumOfQty
      };
      this.apiservice.updateERProductsQTY(payload).subscribe((res: any) => {
        this.getProductsByCustomerId();
        this.clearSelectorder();
      });
    }
  }

  updateQuantityDatabase(stockBuffer: StockBuffer) {
    this.sumStockBuffer(stockBuffer)

    if (this.sumOfQty != '') {
      let payload = {
        "userID": this.current_user.userID,
        "productID": stockBuffer.ProductID,
        "field": "inventory",
        "value": stockBuffer.sumOfQty
      };
      this.apiservice.updateERProductsQTY(payload).subscribe((res: any) => {
        this.getProductsByCustomerId();
        this.clearSelectorder();
      });
    }
  }

  getinput() {
    if (this.input_mask != null && this.input_mask != undefined) {
      this.input_item = this.input.isValid();
    }
  }

  async getorders() {
    let count = 0;
    if (!this.users) {
      this.orderlist = [];
    } else {
      for (let k = 0; k <= this.users.length - 1; k++) {
        this.apiservice.getorder(this.users[k].userID, this.workstationid).subscribe(
          async (res: any) => {
            if (res) {
              if (res.length == 0) {
                count = count + 1;
              } else {
                this.workstationorders?.push(...res);
                count = count + 1;
              }
            } else {
              this.toastrService.error('Something went wrong!', 'Error!');
            }
          }, (error: any) => {
            this.toastrService.error(`Something went wrong! ${error}`, 'Error!');
          });
      }
    }
  }

  async getProductsByCustomerId() {
    const payload = { customer: 0 };
    let response = await this.apiservice.getERPProductsByCustomerId(payload).toPromise();
    this.productList = (response as any)?.result;
    this.stockBufferList = await this.initStockLevelUpdateList()
  }

  async initStockLevelUpdateList() {
    let stockList: StockBuffer[] = []
    let index = 0

    if (this.productList) {
      for (const product of this.productList) {
        let stock: StockBuffer = {
          index: index,
          ProductID: product.ProductID,
          Productname: product.Productname,
          Description: product.Description,
          inventory: product.inventory,
          sumOfQty: product.inventory,
          isSave: false
        }
        index += 1
        stockList.push(stock)
      }
    }

    return stockList
  }


  geterp() {
    this.apiservice.geterp().subscribe(
      (res: any) => {
        if (res.response != false) {
          this.orderlist = res.response.ordernumber_list.ordernummers;
          this.orders_in_use = res.response.ordernumber_list.orders_in_use;

          if (this.orderlist?.length > 0) {
            this.orderlist.forEach((e: any, index: number) => {
              this.orders_in_use.forEach((j: any) => {
                if (e.orderID === j.orderID && j.mapping_field === e.mapping_field) {
                  if ((j.eventType === 'green') || (j.eventType === 'grey')) {
                    e['greenandgrey'] = true;
                    e['displayName'] = j.displayName
                  }
                  if ((j.eventType === 'yellow') || (j.eventType === 'red')) {
                    e['yellowandred'] = true;
                    e['displayName'] = j.displayName
                  }
                }

                // if (j.mapping_field != '' && j.mapping_field != null) {
                //   if (e.mapping_field === j.mapping_field) {
                //     if ((j.eventType === 'green') || (j.eventType === 'grey')) {
                //       e['greenandgrey'] = true;
                //       e['displayName'] = j.displayName
                //     }
                //     if ((j.eventType === 'yellow') || (j.eventType === 'red')) {

                //       e['yellowandred'] = true;
                //     }
                //   }
                // }



              })
              e.displayFields.forEach((j: any, i: number) => {
                if (j === 'numberOfPallets') {
                  this.orderlist[index][j] = this.orderlist[index][j] + ' ' + 'pallets'
                }
                let breakLines = this.orderlist[index][j].replace(/\n/g, "<br>");
                e.displayFields.splice(i, 1, breakLines)
              })
            })
          }
          // console.log(this.orderlist, 'orderlist')
          if (this.orderlist?.length > 0) {
            this.HideSelectButton = false;
            if (this.workstationorders?.length > 0) {
              for (let j = 0; j <= this.orderlist.length - 1; j++) {
                this.orderlist[j].isExist = false;
                this.orderlist[j].username = '';
                for (let k = 0; k <= this.workstationorders.length - 1; k++) {
                  if (this.workstationorders[k].orderID == this.orderlist[j].orderID) {
                    for (let m = 0; m <= this.users.length - 1; m++) {
                      if (this.users[m].userID == this.workstationorders[k].userID) {
                        if (this.orderlist[j].username == '' && this.current_user.displayName != this.users[m].displayName) {
                          this.orderlist[j].username = this.users[m].displayName;
                          this.orderlist[j].isExist = true;
                          break;
                        } else if (this.current_user.displayName != this.users[m].displayName) {
                          let name = this.orderlist[j].username;
                          this.orderlist[j].username = name + ',' + this.users[m].displayName;
                          this.orderlist[j].isExist = true;
                          break;
                        } else {
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            this.HideSelectButton = true;
          }
          const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
          this.orderlist.sort((a: any, b: any) => {
            return collator.compare(a.sortfield, b.sortfield);
          });
        } else {
          this.toastrService.error('No ERP orders available', 'Error!');
          this.orderlist = [];
        }
      }, (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      })
  }

  navigate() {
    if (this.role == 'office') {
      if (this.LandingPageComponent.orders.length > 1) {
        let k = this.LandingPageComponent.orders.filter((item: any) => {
          return (item.orderID != 's' && item.orderID != '');
        });
        this.LandingPageComponent.orders = k;
        (this.LandingPageComponent.orders.length > 0) ? this.LandingPageComponent.lastorder_focus() : '';
        this.LandingPageComponent.set_flag('screen', '');
      }
    } else {
      if (this.DashboardComponent.orders.length > 1) {
        let k = this.DashboardComponent.orders.filter((item: any) => {
          return (item.orderID != 's' && item.orderID != '');
        });
        this.DashboardComponent.orders = k;
        (this.DashboardComponent.orders.length > 0) ? this.DashboardComponent.lastOrderFocus() : '';
        this.DashboardComponent.set_flag('screen', '');
      }
    }
  }
  get f() { return this.form.controls; }
  orders() {
    this.submitButton.nativeElement.disabled = true;
    this.DashboardComponent.userControl = true
    this.DashboardComponent.clearChecklistData()
    if (this.form.value.order != '') {
      if (this.input_mask == null || this.input_mask == undefined) {
        this.input_item = true;
      }
      if (this.form.valid && this.input_item) {
        this.submitButton.nativeElement.disabled = true;
        this.form.value.order = this.customerID === '3' && this.form.value.projectExtention != '' ? this.form.value.order + '-' + this.form.value.projectExtention : this.form.value.order
        let order = this.form.value.order;
        let payload = {
          projectnumber: order
        }
        if (this.customerID === '3') {
          this.apiservice.validateprojectnumber(payload).subscribe(res => {

            this.submitButton.nativeElement.disabled = false;
            if (res?.response === 'success') {
              (this.role === 'shopfloor') ? this.DashboardComponent.addOrder(order, '', '') : this.LandingPageComponent.add_order(order, '', '')
            }
            if (res?.response === 'error') {
              let errormsg;
              if (this.currentlang === 'Dutch') {
                errormsg = 'Ongeldig projectnummer'
              }
              else if (this.currentlang === 'English') {
                errormsg = 'Invalid project number'
              }
              else if (this.currentlang === 'Spanish') {
                errormsg = 'número de proyecto no válido'

              } else { }

              this.toastrService.error(errormsg, 'Error!');
            }

          },
            (error) => console.log(error)
          )
        } else {
          (this.role === 'shopfloor') ? this.DashboardComponent.addOrder(order, '', '') : this.LandingPageComponent.add_order(order, '', '')

        }
      }
    }
    else {
      this.submitted = true
    }
  }

  async NavigateTo(item: string) {

    localStorage.setItem("openOrderss", JSON.stringify(this.orderlist));
    if (item != '1') {
      await this.DataService.add(this.order_to_pass);
    }
    this.modalService.dismissAll();
    this.DashboardComponent.status_screen = false;
    this.DashboardComponent.OrderIntakeScreen = true;
  }

  sendorder(order: any) {
    this.order_to_pass = order;
  }

  clearSelectorder() {
    this.productForm.reset({});
    this.selectedProduct = undefined;
  }

  async open(content: any) {
    await this.getorders();
    await this.geterp();
    setTimeout(() => {
      this.modalInstance = this.modalService.open(content, { windowClass: 'project-list-container', centered: true });
      this.resetTimer()
      this.modalInstance.result.then((result: any) => {
        this.closeResult = `Closed with: ${result}`;
        clearTimeout(this.idleTimer);
        this.SearchText = '';
        // console.log(this.idleTimer);
      }, (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        clearTimeout(this.idleTimer);
        this.SearchText = '';
        // console.log(this.idleTimer);
      });
      const modal: any = document.querySelector("ngb-modal-window");
      modal.style.overflow = "hidden";
    }, 1000);
    setTimeout(() => {
      document.getElementById("searchitem")?.focus()
    }, 1002)
  }
  async opened(content: any) {
    // await this.geterp();
    // setTimeout(() => {
    this.modalInstance = this.modalService.open(content, { centered: true });
    this.resetTimer()
    this.modalInstance.result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
      clearTimeout(this.idleTimer);
      this.SearchText = '';
      // console.log(this.idleTimer);
    }, (reason: any) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      clearTimeout(this.idleTimer);
      this.SearchText = '';
      // console.log(this.idleTimer);
    });
    const modal: any = document.querySelector("ngb-modal-window");
    modal.style.overflow = "hidden";
    setTimeout(() => {
      document.getElementById("searchitem")?.focus()
    }, 200)
    // }, 1000);
  }



  private resetTimer() {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => {
      this.modalInstance?.close();
    }, 300000)
  }
  clearSearch() {
    this.SearchText = '';
    document.getElementById("searchitem")?.focus()
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit() {
    if (this.role === 'shopfloor') {

      localStorage.setItem("openOrderss", JSON.stringify(this.orderlist));
      this.DashboardComponent.addOrder(this.order_to_pass.orderID, this.order_to_pass.mapping_field, this.order_to_pass.orderDescription);
      this.DashboardComponent.erpattachment(this.order_to_pass);
    } else {
      this.LandingPageComponent.add_order(this.order_to_pass.orderID, this.order_to_pass.mapping_field, this.order_to_pass.orderDescription);
      this.LandingPageComponent.erpattachment(this.order_to_pass);
    }
  }
}
