import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { base_routes } from 'src/app/constant';
import { DataService } from 'src/services/dataservice.service';
import { CdTimerComponent, TimeInterface } from 'angular-cd-timer';
import { environment } from '../../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WorkingHoursComponent } from '../working-hours/working-hours.component';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/de';
import { Order, currentOrder } from '../../models/order';
import moment from 'moment';
import { ChecklistModalComponent } from '../checklist-modal/checklist-modal.component';
import { AvailableUsers, Checklist } from '../../models/checklist';
import { customizing, responseCustomizing } from '../../models/customizing';
import { categorie, categories } from '../../models/categories';
import { DatePipe } from '@angular/common';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MM YYYY',
  },
};
declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [DatePipe, WorkingHoursComponent,
    //  {provide: MAT_DATE_LOCALE, useValue: 'nl-nl'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  host: {
    '[class.has-sidebar]': '!user_screen',
  }
})
export class LandingPageComponent implements OnInit {
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild('yellowpopup') private yellowpopup: TemplateRef<Object>;
  @ViewChild('attachment') private contentRef: TemplateRef<Object>;
  @ViewChild('logoutt') private logoutRef: TemplateRef<Object>;
  @ViewChild('darkblueModal') private darkblueRef: TemplateRef<Object>;
  @ViewChild(WorkingHoursComponent) child: WorkingHoursComponent;
  // @ViewChild(DownTimeComponent) DowntimeChild:DownTimeComponent;
  timerSubscription: Subscription;
  private socket: any;
  @ViewChild('basicTimer') basicTimer: CdTimerComponent;
  timer_flag = false;

  order_numbers: any;
  screen_status: boolean = false;
  downtime_flag: boolean = false;
  workinghours_flag: boolean = false;
  unplanned_flag: boolean = false;
  reports_flag: boolean = false;
  project_flag: boolean = false;
  // reportstatus_flag: any;
  green_screen: boolean;
  greencat_flag: boolean = false;
  data: any;
  yellow_screen: boolean;
  red_screen: boolean;
  user_screen: boolean = false;
  status_screen: boolean = true;
  currentuser: any;
  user_data: any;
  name: string | null;
  orders: any = [];
  modal_item: any;
  interval: any;
  display: string;

  seconds: number = 0;
  minutes: number = 0;
  hours: number = 0;
  statusflag: string | null;

  counter: any;
  starttime: any;
  active_order: any;
  order_flag: any;
  deactivate: any;
  workstationid: string | null;
  active_users: AvailableUsers[];
  categories: categorie[];
  reportstatus_flag: boolean = false;
  report_eventDesc: any;
  report_event: any;
  available_users: any;
  userIds: any;
  green_eventdata: any;
  closeResult: string;
  activityForm: any;
  src: any;
  disablegreen: boolean = false;
  customerID: any;
  redcategories: any;
  pre_orders: any;
  bluecategories: any;
  report_blue: any;
  orderss: any;
  person: string | null;
  users: any;
  adduserflag: boolean = false;
  attachments: any;
  green_categories: any;
  allow_greensubcat: boolean = false;
  attachment_flag: any;
  temp_orders: any;
  control: boolean = false;
  browserLang: any;
  lang_array: any = [];
  currentlang: string;
  current_temp_order: any;
  clicks: boolean = true;
  order_viewdisable: boolean = false;
  config: NgxUiLoaderConfig;
  disable_ordertabs: boolean;
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  timer_Interval: any;
  timer_format: string;
  timer_id: any;
  countuptimer: NodeJS.Timer;
  t: string;
  deactivate_timer: boolean;
  registrationForm: any;
  darkblue_event: any = [];
  showorangetimer: boolean;
  workingon_flag: boolean = false;
  current_screen: string | null;
  reminder: any;
  statusForm: any;
  minDate: Date;
  show_checkbox: boolean;
  reminder_order: currentOrder;
  checklist_flag: any;
  checkListItems: any;
  checkListFormGroup: any;
  customizing: any;
  goodsReciptFlag: string;
  role: string | null;
  YellowEventID: any;
  GreenEventID: number;
  dialogType: string;
  isShow: boolean;
  dataToBeCreatedAfterDB: { flag: any; eventDesc: any; item: any; };
  currentOrder: any;
  blueOrder = false;
  obj: any;
  constructor(private datePipe: DatePipe, private ChecklistModalComponent: ChecklistModalComponent, private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    public dataService: DataService, private deviceService: DeviceDetectorService, private sanitizer: DomSanitizer, private translate: TranslateService, private toastrService: ToastrService,
    private apiservice: ApiService, public fb: UntypedFormBuilder, private modalService: NgbModal, private ngxService: NgxUiLoaderService, private router: Router) {
    this.deactivate_timer = environment.Deactivate_timer;
    this.name = localStorage.getItem('smartbox_name');
    this.workstationid = localStorage.getItem('workstationID');
    this.customerID = localStorage.getItem('customerID');
    // console.log(this.customerID, 'customerID')
    this.registrationForm = this.fb.group({
      timer: ['', [Validators.required]],
      passwords: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.statusForm = this.fb.group({
      red_radio: ['false'],
      date: ['', [Validators.required]],
      delete_checkbox: [false],
    });
    this.activityForm = this.fb.group({
      event: ['', [Validators.required]],
    });
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    this.minDate = currentDate;
    this.reminder = localStorage.getItem('reminder');
    this.role = localStorage.getItem("role");
    (this.reminder != '' && this.reminder != null && this.reminder != undefined) ? this.reminder = JSON.parse(this.reminder as any) : this.reminder = [];
    this.setNotification(this.reminder);
    // this.lang_array = [
    //   { name: 'Dutch', value: 'du' },
    //   { name: 'English', value: 'en' },
    //   { name: 'Spanish', value: 'es' },
    // ];
    this.name = localStorage.getItem('smartbox_name');
    this.statusflag = localStorage.getItem("status_flag");
    this.currentlang = 'Dutch';
    localStorage.setItem('language', this.currentlang);
    this.orders.forEach((element: any) => {
      if (element.focus == "true") {
        this.active_order = element;
      }
    });
    this.getusers();
  }


  setLocaleDatepicker(item: any) {
    if (item === 'du') {
      this._locale = 'nl-nl';
    } else {
      this._locale = item;
    }
    this._adapter.setLocale(this._locale);
  }

  deleteEvent() {
    this.show_checkbox = true;
    if (this.statusForm.value.delete_checkbox == true) {
      this.reminder = localStorage.getItem("reminder");
      (this.reminder) ? this.reminder = JSON.parse(this.reminder) : '';
      this.reminder = this.reminder.filter((element: any) => {
        return (element.orderID != this.reminder_order.orderID)
      })

      this.modalService.dismissAll();
      this.orders.forEach((element: any) => {
        element.notification = false
      });
      if (this.reminder.length && this.reminder.length > 0) {
        localStorage.setItem('reminder', JSON.stringify(this.reminder));
        this.setNotification(this.reminder);
      } else {
        localStorage.removeItem('reminder');
      }
    }
  }

  get_ordernotification(order: any) {
    this.reminder_order = order;
    // console.log(order, 'order')

  }

  getCategories(userid: any) {
    this.apiservice.getcategories(userid).subscribe(
      (res: categories) => {
        if (res) {
          this.categories = res.yellow;
          this.redcategories = res.red;
          this.bluecategories = res.blue;
          this.report_blue = res.level2.blue;
          let greenCategories: any = res.green;
          this.green_categories = greenCategories?.filter((item: any) => {
            return (item.hidden != '1' && item.text != null)
          });
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          (this.report_blue?.length > 0 && this.report_blue != undefined) ? localStorage.setItem('reportblue', JSON.stringify(this.report_blue)) : '';
          if (this.green_categories?.length > 0) {
            this.allow_greensubcat = true;
            localStorage.setItem(
              'greencategories',
              JSON.stringify(this.green_categories)
            );
          } else {
            this.allow_greensubcat = false;
          }
        } else {
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          this.toastrService.error('No categories!', 'Error!');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }


  async Post_formFields(data: any) {
    await this.apiservice.addformfield(data).toPromise();
  }

  async getusers() {
    if (this.role == 'office') {
      let userIDs: any = [];
      let res: any = await this.apiservice.getAvailableUsersList(userIDs, this.workstationid).toPromise()
      if (res.res) {
        if (res.res.length == 0) {
          this.active_users = [];
          // this.getavailableusers();
          // this.users_list();
          if (this.timer_Interval == undefined) {
            this.timer_Interval = setInterval(() =>
              window.location.reload(), 900000);
          }
          this.orders = [];
          this.t = "00:00:00";
          this.active_order = undefined;
          this.report_event = undefined;
          this.currentlang = 'Dutch';
          localStorage.setItem('language', this.currentlang);
          this.child.set_dates('2');
          this.translate.use('du');
          localStorage.removeItem('users');
        } else {
          this.active_users = res.res;
          // console.log(this.active_users, 'active_users')
          this.currentuser = this.active_users[0];
          localStorage.setItem(
            'current_user',
            JSON.stringify(this.currentuser)
          );
          this.check_screen_play();
          this.getorders(this.currentuser.userID, this.workstationid, false);
        }
      } else {
        this.toastrService.error('No user found!', 'Error!');
      }
    }
  }

  check_screen_play() {
    let flag = localStorage.getItem(this.currentuser.userID + 'screen_play');
    (flag == 'true') ? (this.control = true) : (this.control = false);
  }

  async add_order(data: any, mapping_field: any, orderDescription: any) {
    if (data != 's') {
      let errorflag;
      for (let k = 0; k <= this.orders.length - 1; k++) {
        if (this.orders[k].orderID == data) {
          errorflag = true;
          break;
        }
      }
      if (errorflag == true) {
        this.toastrService.error('Order already exist', 'error');
      } else {
        let temp: any[] = [];
        this.orders.forEach((element: any) => {
          if (element.focus == true) {
            temp.push(element);
            element.orderID = data;
            element.mapping_field = mapping_field;
            element.orderDescription = orderDescription;
            this.active_order = element;
            localStorage.setItem(
              'active_order',
              JSON.stringify(this.active_order)
            );
            localStorage.setItem(
              this.currentuser.userID + 'focus_order',
              JSON.stringify(this.active_order)
            );
          }
        });
        this.temp_orders = localStorage.getItem(this.currentuser.userID);
        if (this.temp_orders) {
          this.temp_orders = JSON.parse(this.temp_orders);
          this.temp_orders.forEach((element: any) => {
            temp.push(element);
          });
        }
        localStorage.setItem(this.currentuser.userID, JSON.stringify(temp));
        this.current_screen = "project_tab";
        this.screen_status = true;
        this.workinghours_flag = false;
        this.downtime_flag = false;
        this.unplanned_flag = false;
        this.reports_flag = false;
        this.project_flag = false;
        this.workingon_flag = false;
        this.current_screen = 'project_tab';
        var collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base',
        });
        let args = 'orderID';
        this.orders.sort((a: any, b: any) =>
          collator.compare(a[args], b[args])
        );
      }
    } else {
      data = data == 's' ? '' : data;
      this.orders.forEach((element: any) => {
        element.focus = false;
      });
      this.orders.push({ orderID: data, eventType: '', focus: true });
      ((this.countuptimer)) ? (clearInterval(this.countuptimer), this.t = "00:00:00") : '';
      this.orders.forEach((element: any) => {
        if (element.focus == true) {
          element.orderID = data;
          this.active_order = element;
          localStorage.setItem(
            'active_order',
            JSON.stringify(this.active_order)
          );
        }
      });
      this.screen_status = false;
      this.workinghours_flag = false;
      this.downtime_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.workingon_flag = false;
      this.project_flag = true;
      this.current_screen = 'order_tab';
    }
  }

  async erpattachment(data: any) {
    this.apiservice.getattachment(data, this.workstationid).subscribe(
      (res: any) => {
        if (res.result && res.result.files.length > 0) {
          this.attachments = res.result.files;
          this.deviceInfo = this.deviceService.getDeviceInfo();
          if (this.deviceInfo.deviceType == 'desktop') {
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
              environment.fileurl +
              res.result.files[0].filename +
              '#toolbar=0&navpanes=0&scrollbar=0'
            );
          } else {
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
              'https://docs.google.com/gview?url=' +
              environment.fileurl +
              res.result.files[0].filename +
              '#embedded=true'
            );
          }
        } else {
          this.attachments = undefined;
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  getChecklistItems(userID: string, orderID: string, mappingfield: string, flag: string, checklistFlag: any, stepflag: string): void {
    let orderKey = mappingfield ? mappingfield : '';
    let payload = {
      "processStep": flag,
      "orderID": orderID,
      "userID": userID,
      "orderkey": mappingfield ? mappingfield : '',
    };
    this.apiservice.getChecklist(userID, flag, orderID, orderKey).subscribe(
      (res: Checklist) => {
        if (res.response == 'success') {
          if (res.fields.length > 0) {
            this.dialogType = res.dialogType;
            this.checkListItems = res?.fields;
            const formGroup: any = {};
            for (let prop of Object.keys(this.checkListItems)) {
              let key = this.checkListItems[prop].fieldName;
              let value;
              let date;
              if (this.checkListItems[prop].fieldType == 'date') {
                if (this.checkListItems[prop].value != '') {
                  let temp = this.checkListItems[prop].value.split('-');
                  date = { date: parseInt(temp[0]), month: parseInt(temp[1]) - 1, year: parseInt(temp[2]) }
                  value = moment(date);
                } else {
                  value = '';
                }
              } else {
                value = this.checkListItems[prop].value;
              }
              formGroup[key] = new FormControl(value);
              (this.checkListItems[prop]?.protected == true) ? formGroup[key].disable() : '';
            }
            this.checkListFormGroup = new FormGroup(formGroup);
            formGroup['details'] = new FormControl(payload);
            this.modalComponent.openInstructionList(this.modalComponent.instructionspopup, payload, checklistFlag, stepflag, this.attachments, this.dialogType, '', false, false);
          }
        }
      });
  }

  async submitHandler(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = "true";
    this.modalService.dismissAll();
    await this.stop(this.darkblue_event[this.darkblue_event.length - 1], 'darkblue', true);
    await this.createorder(this.dataToBeCreatedAfterDB.flag, this.dataToBeCreatedAfterDB.eventDesc, this.dataToBeCreatedAfterDB.item);
  }

  async createorder(flag: any, eventDescription: any, item: any) {
    if (this.darkblue_event.length >= 1 && flag === 'green') {
      this.modalService.open(this.darkblueRef);
      this.dataToBeCreatedAfterDB = {
        flag: flag,
        eventDesc: eventDescription,
        item: item
      };
    } else {
      if (this.active_order?.orderID != '') {
        this.order_viewdisable = true;
        this.current_temp_order = this.active_order.orderID;
        var unixtimestamp = Math.floor(new Date().getTime() / 1000);
        let datas = {
          orderNr: item.orderID,
          customerRef: '',
          orderkey: item.mapping_field ? item.mapping_field : '',
          userID:
            this.currentuser != undefined
              ? this.currentuser.userID
              : eventDescription.userID,
          actionType: flag,
          eventDescription: eventDescription.value
            ? eventDescription.eventDescription
            : eventDescription,
          actionDetail: eventDescription.value
            ? eventDescription.value
            : eventDescription,
          workstationID: this.workstationid,
          StartTime: unixtimestamp,
        };
        datas.userID != undefined ? '' : (datas.userID = eventDescription.userID);
        this.green_eventdata ? (datas.orderNr = this.green_eventdata.orderID) : '';
        this.green_eventdata = undefined;
        (flag == 'green' && !this.green_categories) ? (datas.actionDetail = null) : '';
        let res: any = await this.apiservice.addorder(datas).toPromise();
        if (res.response == 'success') {
          this.isShow = false;
          if (flag == 'yellow') {
            this.YellowEventID = res.eventID;
          } else if (flag == 'green') {

            this.GreenEventID = res.eventID;
          } else {
          }
          let temp: any;
          let checklist;
          if (this.customizing?.showChecklist != null) {
            await this.customizing?.showChecklist.some((element: any) => {
              if (element == 'afterGreen') {
                checklist = true;
                temp = element;
                return true;
              } else if (element == 'dependingOnOrderResponse') {
                if (res.triggerChecklist) {
                  checklist = true;
                  temp = res?.triggerChecklist;
                } else {
                  checklist = false;
                }
              } else if (element == 'preSSOP') {
                checklist = true;
                temp = element;
              } else if (element == 'afterGreenClosed') {
                checklist = false;
              } else if (element == 'oncePerDayAfterGreenStart') {
                let greendate = localStorage.getItem("onegreenperday");
                let tempdate = new Date();
                let year = tempdate.getFullYear();
                let month = tempdate.getMonth() + 1;
                let day = tempdate.getDate();
                let date = day + '-' + month + '-' + year;
                if (greendate && greendate == date.toString()) {
                  checklist = false;
                } else {
                  temp = element;
                  localStorage.setItem("onegreenperday", date);
                  checklist = true;
                }
              } else {
              }
            });
          } else {
            temp = '';
            checklist = false;
          }
          if (this.customizing?.triggerPrint != null) {
            this.customizing?.triggerPrint?.forEach((element: any) => {
              if (element.processStep == 'dependingOnOrderResponse') {
                if (res?.triggerPrint != null) {
                  this.postPrintFlags(res.triggerPrint);
                }
              }
            });
          }
          (temp == undefined) ? temp = '' : '';
          (((checklist == true || this.customizing.showInstructions == 'afterGreen') && flag == 'green')) ?
            ((localStorage.setItem("lastflag", "afterGreen")), await this.erpattachment(this.active_order), this.goodsReciptFlag = 'afterGreen', this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, this.active_order?.mapping_field, temp, checklist, 'afterGreen')) : '';
          if (flag == 'yellow' && this.showorangetimer == true) {
            let datas = {
              "ordernr": res?.orderID,
              "eventID": res?.eventID,
            }
            localStorage.setItem("DateForOrange", JSON.stringify(datas))
          }
          if (flag == 'blue') {
          } else if (this.currentuser == undefined) {
          } else {
            this.current_temp_order == this.active_order.orderID
              ? (this.current_temp_order = res.orderID)
              : (this.current_temp_order = undefined);
            if (this.active_order.eventType == '') {
              this.current_temp_order = this.active_order.orderID;
              let id = this.active_order.orderID;
              this.temp_orders = localStorage.getItem(this.currentuser.userID);
              this.temp_orders = JSON.parse(this.temp_orders);
              if (this.temp_orders && this.temp_orders.length > 0) {
                let k = this.temp_orders.filter((item: any) => {
                  return item.orderID != id
                });
                localStorage.setItem(this.currentuser.userID, JSON.stringify(k));
              }
            }
            if (item.orderID != this.active_order.orderID) {
              this.orders.forEach((element: any) => {
                if (element.orderID == item.orderID) {
                  element.eventType = flag;
                }
              });
            } else {
              this.active_order.eventType = flag;
              this.active_order.eventID = res.eventID;
              this.setOpenorders(res.openOrders);
            }
            // this.setOpenorders(res.openOrders);
            // await this.getorders(this.currentuser.userID, this.workstationid,false);
          }
        } else {
          this.toastrService.error(`${res.errorMessage}`, 'Error!');
        }
        this.order_viewdisable = false;

      }
      // }else{
      //   localStorage.removeItem('active_order');
      //   this.orders = [];
      //   this.t = "00:00:00";
      //   localStorage.removeItem(this.currentuser.userID + 'focus_order');
      //   localStorage.removeItem(this.currentuser.userID);
      //   this.add_order('s')
      //   this.getorders(this.currentuser.userID,this.workstationid);
      // }
    }
  }

  postPrintFlags(flags: any) {
    flags?.forEach((element: any) => {
      let payload = {
        "quantity": null,
        "userID": this.currentuser.userID,
        "processStep": element
      };
      this.apiservice.addQuantity(payload).subscribe(
        (res: any) => {
        },
        (error: any) => {
          console.log(error)
        }
      );
    });
  }

  setNotification(items: any) {
    window.setInterval(() => {
      // var unixtimestamp = Math.floor(new Date().getTime() / 1000);
      let unixtimestamp = new Date();
      let fixedDate: any = this.datePipe.transform(unixtimestamp, 'dd/MM/yyyy')
      let temp = [];
      for (let item in items) {
        let setformatdate = items[item].endTimestamp ? new Date(+items[item].endTimestamp * 1000) : null;
        let cDate: any = setformatdate ? this.datePipe.transform(setformatdate, 'dd/MM/yyyy') : null;
        if (items[item]?.notify == false) {
          if (cDate <= fixedDate) {
            items[item].notify = true;
            temp.push(items[item])
          }
        }
      }
      for (let data in this.orders) {
        // if(this.orders[data].reminderDate >= unixtimestamp){
        //   this.orders[data].notification = true;
        // }
        for (let x in temp) {
          if (temp[x].orderID == this.orders[data].orderID && temp[x].notify == true) {
            this.orders[data].notification = true;
          }
        }
      }
    }, 3000);
  }

  SetDateYellow(res: any) {
    let datas = localStorage.getItem("DateForOrange");
    datas = JSON.parse(datas as any);
    (datas as any).ordernr = res.orderID;
    (datas as any).eventID = res.eventID;
    this.apiservice.SetTimerForYellow(datas).subscribe((data: any) => {
    })
  }

  controlStatus() {
    if (this.user_screen == false) {
      this.control = !this.control;
      if (this.control === true) {
        localStorage.setItem(this.currentuser.userID + 'screen_play', 'true');
        this.orders.forEach((element: any) => {
          if (element.eventType == 'green') {
            localStorage.setItem(`${element.orderID}`, element.eventType);
            this.active_order = element;
            this.stop(element, 'green', false);
            this.createorder('yellow', '6', this.active_order);
          } else if (element.eventType === 'darkblue') {
            localStorage.setItem(`${element.orderID}`, element.eventType);
            this.active_order = element;
            this.stop(element, 'darkblue', false);
            this.createorder('yellow', '6', this.active_order);
          } else {
          }
        });
      } else {
        localStorage.setItem(this.currentuser.userID + 'screen_play', 'false');
        this.orders.forEach((element: any) => {
          if (element.eventType == 'yellow' && element.eventDetail == '6') {
            let flag_order = localStorage.getItem(`${element.orderID}`);
            if (flag_order === 'green') {
              this.active_order = element;
              this.stop(element, 'yellow', false);
              this.createorder('green', '46', this.active_order);
            } else if (flag_order === 'darkblue') {
              this.active_order = element;
              this.stop(element, 'yellow', false);
              this.createorder('darkblue', '406', this.active_order);
            } else {
            }
            // this.active_order = element;
            // this.stop(element, 'yellow');
            // this.createorder('green', '46');
          }
          localStorage.removeItem(`${element.orderID}`);
        });
      }
    }
  }

  setModal(item: string) {
    this.dataService.sendModalValue(item);
  }

  setLang(lang: any) {
    // if (lang.name != this.currentlang) {
    this.currentlang = lang.name;
    localStorage.setItem('language', this.currentlang);
    this.translate.use(lang.value);
    let item;
    // (lang.value == 'du') ? item = 'du' : item = 'en';
    if (lang.value === 'du') {
      item = 'du'
    } else if (lang.value === 'en') {
      item = 'en';
    } else {
      item = 'es';
    }
    this.dataService.changeMessage(item);
    this.dataService.updateData(item);
    //this.child.language = this.currentlang;
    //this.child.set_dates('2');
  }

  setOpenorders(openorders: any) {
    if (openorders.length > 0) {
      openorders.forEach((element: any) => {
        if (this.active_order.orderID == element.orderID) {
          this.active_order.matched = true;
          this.active_order.eventType = element.eventType;
          this.active_order.startTime = element.startTime;
          this.active_order.endTime = element.endTime;
          this.active_order.eventID = element.eventID;
          this.active_order.monitorStartTime = element.monitorStartTime;
          this.active_order.monitorEndTime = element.monitorEndTime;
          this.active_order.fkWorkstationID = element.fkWorkstationID;
          this.active_order.userID = element.userID;
          this.active_order.eventDescription = element.eventDescription;
          this.active_order.languageKey = element.languageKey;
          this.active_order.eventDetail = element.eventDetail;
          this.active_order.focus = true;
          localStorage.setItem("active_order", JSON.stringify(this.active_order))
        }
        this.orders.forEach((item: any) => {
          if (item.orderID == element.orderID) {
            item.matched = true;
            item.eventType = element.eventType;
            item.startTime = element.startTime;
            item.endTime = element.endTime;
            item.eventID = element.eventID;
            item.monitorStartTime = element.monitorStartTime;
            item.monitorEndTime = element.monitorEndTime;
            item.fkWorkstationID = element.fkWorkstationID;
            item.userID = element.userID;
            item.eventDescription = element.eventDescription;
            item.languageKey = element.languageKey;
            item.eventDetail = element.eventDetail;
          }
        });
      })
      this.start(this.active_order, this.active_order.eventType);
    }
  }

  async getorders(userid: any, workstationID: any, content: any) {
    this.apiservice.getorder(userid, workstationID).subscribe(
      async (res: any) => {
        if (res) {
          this.pre_orders = localStorage.getItem('orders');
          let allow_flag = false;
          if (res) {
            if (JSON.stringify(res) == this.pre_orders) {
              allow_flag = true;
            } else {
              allow_flag = false;
              localStorage.setItem('orders', JSON.stringify(res));
            }
          } else {
            allow_flag = false;
            localStorage.setItem('orders', JSON.stringify(res));
          }
          this.current_screen = localStorage.getItem("current_screen");
          if (allow_flag == true) {
          } else {
            this.orderss = res;
            let count = 0;
            this.orderss = this.orderss.filter((element: any, index: any, object: any) => {

              if (element.eventType == 'blue') {
                count = count + 1;
              }
              if (element.eventType == 'darkblue') {
                this.darkblue_event.push(element);
              }
              if (element.eventType != 'darkblue') {
                return element;
              }

            });
            if (this.current_screen == 'workinghours_flag') {
              this.navigate_cal();
            }
            if (this.orders.length > 0) {
              this.orders.forEach((element: any, index: any, object: any) => {
                element.focus = false;
                element.matched = false;
                for (let item in this.orderss) {
                  if (element.orderID == this.orderss[item].orderID) {
                    element.matched = true;
                    element.eventType = this.orderss[item].eventType;
                    element.startTime = this.orderss[item].startTime;
                    element.endTime = this.orderss[item].endTime;
                    element.eventID = this.orderss[item].eventID;
                    element.monitorStartTime = this.orderss[item].monitorStartTime;
                    element.monitorEndTime = this.orderss[item].monitorEndTime;
                    element.fkWorkstationID = this.orderss[item].fkWorkstationID;
                    element.userID = this.orderss[item].userID;
                    element.eventDescription = this.orderss[item].eventDescription;
                    element.eventDetail = this.orderss[item].eventDetail;
                    element.focus = false;
                  }
                  if (this.orderss.length - 1 === parseInt(item) && element.matched == false && element.eventType != '') {
                    object.splice(index, 1);
                  }
                }
              });
            } else {
            }
            this.temp_orders = localStorage.getItem(this.currentuser.userID);
            this.temp_orders = JSON.parse(this.temp_orders);
            let nofocus;
            let focus_order: any;
            this.orders = [];
            if (this.orders?.length == 0 && (res.length == 0 || this.orderss.length == count)) {
              if (this.currentuser && this.workinghours_flag == false) {
                let check = localStorage.getItem(
                  this.currentuser.userID + 'focus_order'
                );
                check = JSON.parse(check as any);
                nofocus = true;
                if (this.temp_orders && this.temp_orders.length > 0) {
                  await this.temp_orders.forEach(async (element: any) => {
                    if (element.eventType == '') {
                      element.focus = false;
                      if (check != undefined && check != null) {
                        if (element.orderID == (check as any).orderID) {
                          focus_order = element;
                          nofocus = false;
                          element.focus = true;
                        }
                      }
                      this.orders.push(element)
                    }
                  });
                  var collator = new Intl.Collator(undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  });
                  let args = 'orderID';
                  this.orders.sort((a: any, b: any) =>
                    collator.compare(a[args], b[args])
                  );
                  localStorage.setItem(this.currentuser.userID, JSON.stringify(this.orders));
                  if (this.orders.length == 0) {
                    localStorage.removeItem(this.currentuser.userID)
                    this.add_order('s', '', '');
                    this.active_order = this.orders[0];
                  }
                  if (this.current_screen != 'workinghours_flag') {
                    if (nofocus == true) {
                      this.lastorder_focus();
                    }
                    if (focus_order?.orderID != '') {
                      this.current_screen = 'order_tab';
                      this.workinghours_flag = false;
                      this.screen_status = true;
                      this.downtime_flag = false;
                      this.workinghours_flag = false;
                      this.unplanned_flag = false;
                      this.reports_flag = false;
                      this.project_flag = false;
                    } else {
                      this.workinghours_flag = false;
                      this.screen_status = false;
                      this.downtime_flag = false;
                      this.workinghours_flag = false;
                      this.unplanned_flag = false;
                      this.reports_flag = false;
                      this.project_flag = true;
                      this.current_screen = 'project_tab';
                    }
                  } else {
                    this.workinghours_flag = true;
                    this.screen_status = false;
                    this.downtime_flag = false;
                    this.workinghours_flag = false;
                    this.unplanned_flag = false;
                    this.reports_flag = false;
                    this.project_flag = false;
                    this.navigate_cal();
                  }
                  //(nofocus == true && this.current_screen != 'workinghours_flag') ? this.lastorder_focus() : '';

                } else {
                  this.orders = [];
                  (this.countuptimer) ? clearInterval(this.countuptimer) : '';
                  this.t = "00:00:00";
                  this.add_order('s', '', '');
                  this.orders[0].focus = true;
                  this.active_order = this.orders[0];
                  if (this.current_screen == 'workinghours_flag') {
                    this.workinghours_flag = true;
                    this.navigate_cal();
                  } else {
                    this.current_screen = 'project_tab';
                    this.project_flag = true;
                  }
                  this.screen_status = false;
                  this.downtime_flag = false;
                  this.unplanned_flag = false;
                  this.reports_flag = false;
                }
              }
            } else {
              if (this.currentuser) {
                if (this.workinghours_flag == false && this.current_screen != 'workinghours_flag') {
                  if (this.orderss.length >= 1) {
                    this.current_screen = 'order_tab';
                    this.screen_status = true;
                    this.workinghours_flag = false;
                  }
                } else {
                  this.screen_status = false;
                  this.navigate_cal();
                  this.workinghours_flag = true;
                  this.current_screen = 'workinghours_flag';
                }
                this.downtime_flag = false;
                this.unplanned_flag = false;
                this.reports_flag = false;
                this.project_flag = false;
                let arrayy = [];
                let bluearrayy = [];
                for (let k = 0; k <= this.orderss.length - 1; k++) {
                  this.orderss[k].focus = false;
                  if (this.orderss[k].eventType != 'blue') {
                    arrayy.push(this.orderss[k]);
                  } else {
                    bluearrayy.push(this.orderss[k]);
                  }
                }
                if (bluearrayy && bluearrayy.length > 0) {
                  for (let i = 0; i < bluearrayy.length; i++) {
                    if (bluearrayy[i].eventDescription.includes('Signaal')) {
                      this.reportstatus_flag = true;
                      this.person = localStorage.getItem('blueperson');
                      this.report_event = bluearrayy[i];
                    }
                  }
                } else {
                  this.reportstatus_flag = false;
                }
                if (this.orders?.length == 0) {
                  this.orders = arrayy;
                } else {
                  this.orders = [...arrayy];
                }
                this.temp_orders = localStorage.getItem(this.currentuser.userID);
                if (this.temp_orders) {
                  (typeof (this.temp_orders) == 'string') ? this.temp_orders = JSON.parse(this.temp_orders) : '';
                  this.temp_orders.forEach((element: any) => {
                    for (let item in this.orders) {
                      if (element.eventType == '' && element.orderID != this.orders[item].orderID) {
                        if (this.orders.length - 1 == parseInt(item)) {
                          this.orders.push(element)
                        }
                      } else if (element.orderID == this.orders[item].orderID) {
                        break;
                      } else {
                      }
                    }
                  });
                }
                var collator = new Intl.Collator(undefined, {
                  numeric: true,
                  sensitivity: 'base',
                });
                let args = 'orderID';
                this.orders.sort((a: any, b: any) =>
                  collator.compare(a[args], b[args])
                );
                if (this.current_temp_order) {
                  this.orders.forEach((element: any) => {
                    if (element.orderID == this.current_temp_order) {
                      localStorage.setItem(
                        this.currentuser.userID + 'focus_order',
                        JSON.stringify(element)
                      );
                    }
                  });
                }
                if (this.workinghours_flag == false || this.workinghours_flag == undefined && this.current_screen != 'workinghours_flag') {
                  let check = localStorage.getItem(
                    this.currentuser.userID + 'focus_order'
                  );
                  check = JSON.parse(check as any);
                  if (!check || (check as any).orderID == '') {
                    this.lastorder_focus();
                  } else {
                    let order_flag = false;
                    this.orders.forEach((element: any) => {
                      element.focus = false;
                      if (element.orderID == (check as any).orderID) {
                        order_flag = true;
                        element.focus = true;
                        this.active_order = element;
                        localStorage.setItem(
                          'active_order',
                          JSON.stringify(this.active_order)
                        );
                      }
                    });
                    (order_flag == false && this.current_screen != 'workinghours_flag') ? this.lastorder_focus() : '';
                  }
                  localStorage.setItem(
                    this.currentuser.userID,
                    JSON.stringify(this.orders)
                  );
                  this.start(this.active_order, this.active_order.eventType);
                  this.erpattachment(this.active_order);
                  this.setNotification(this.reminder);
                  let array = [];
                  array[0] = this.currentuser;
                  // this.getusericon(this.active_users);
                  this.getnotes();
                }
              }
            }
          }
        } else {
          this.toastrService.error('No orders for this user!', 'Error!');
        }
        let unixtimestamp = new Date();
        let fixedDate: any = this.datePipe.transform(unixtimestamp, 'dd/MM/yyyy');
        for (let data in this.orders) {
          let setformatdate = +this.orders[data].reminderDate ? new Date(+this.orders[data].reminderDate * 1000) : null;
          let cDate: any = setformatdate ? this.datePipe.transform(setformatdate, 'dd/MM/yyyy') : null;
          if (cDate <= fixedDate) {
            this.orders[data].notification = true;
          }
        }
        if (content) {
          let ordeders = this.orders.filter((v: any) => v.orderID == this.currentOrder.orderID)
          let date = new Date(+ordeders[0]?.reminderDate * 1000);


          // this.statusForm.get('date').setValue(date)
          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  lastorder_focus() {
    this.orders[this.orders.length - 1].focus = true;
    this.active_order = this.orders[this.orders.length - 1];
    localStorage.setItem('active_order', JSON.stringify(this.active_order));
    localStorage.setItem(
      this.currentuser.userID + 'focus_order',
      JSON.stringify(this.active_order)
    );
    if (this.active_order?.orderID != '' && this.current_screen != 'workinghours_flag') {
      this.screen_status = true;
      this.workingon_flag = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.workingon_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
    }
  }


  start(flag: any, color: any) {
    if (flag.eventType == '') {
      (this.countuptimer) ? clearInterval(this.countuptimer) : '';
      this.t = "00:00:00";
    } else {
      //this.timer_flag = false;
      const unixTimestamp = flag.monitorStartTime;
      const milliseconds = unixTimestamp * 1000; // 1575909015000
      const dateObject = new Date(milliseconds);
      var m_date =
        dateObject.getFullYear() +
        '-' +
        (dateObject.getMonth() + 1) +
        '-' +
        dateObject.getDate();
      var m_time =
        dateObject.getHours() +
        ':' +
        dateObject.getMinutes() +
        ':' +
        dateObject.getSeconds();
      var m_dateTime = new Date(m_date + ' ' + m_time);
      this.timer(m_dateTime);
      this.disablegreen = false;
    }
  }

  open_modal(data: any) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType == 'desktop') {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.fileurl +
        data.filename +
        '#toolbar=0&navpanes=0&scrollbar=0'
      );
    } else {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://docs.google.com/gview?url=' +
        environment.fileurl +
        data.filename +
        '&embedded=true'
      );
    }
  }

  async open_attachment() {
    await this.erpattachment(this.active_order);
    if (this.attachments) {
      this.openLg(this.contentRef)
    } else {
      this.checkListItems = [];
      this.goodsReciptFlag = '';
      this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, this.active_order?.mapping_field, '', false, 'document');
    }
    // await this.erpattachment(this.active_order);
    // this.openLg(this.contentRef);
  }

  openLg(content: any) {
    this.modalService.open(content, { windowClass: 'myCustomModalClass' });
  }

  timer(countDownDate: any) {
    (this.countuptimer) ? clearInterval(this.countuptimer) : '';
    this.countuptimer = setInterval(() => {
      var now = new Date().getTime();
      var distance = now - countDownDate.getTime();
      var days: any = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours: any = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
      // (days < 10) ? days = "0" + days : days = days;
      (hours < 10) ? hours = "0" + hours : hours = hours;
      (minutes < 10) ? minutes = "0" + minutes : minutes = minutes;
      (seconds < 10) ? seconds = "0" + seconds : seconds = seconds;
      if (days > 0) {
        this.t = days + "d " + hours + ":" + minutes + ":" + seconds;
      } else {
        this.t = hours + ":" + minutes + ":" + seconds;
      }
      (!this.t) ? this.t = "00:00:00" : '';
    }, 1000);
  }

  async focus_button(item: any) {
    localStorage.setItem("current_screen", 'order_tab');
    this.current_screen = 'order_tab';
    this.orders.forEach((element: any) => {
      element.focus = false;
    });
    item.focus = true;
    this.active_order = item;
    this.erpattachment(this.active_order);
    (item.orderID != '') ? (this.screen_status = true, this.project_flag = false) : (this.screen_status = false, this.project_flag = true);
    this.workinghours_flag = false;
    this.downtime_flag = false;
    this.workingon_flag = false;
    this.unplanned_flag = false;
    this.reports_flag = false;
    (item.eventType == '' && (this.countuptimer)) ? (clearInterval(this.countuptimer), this.t = "00:00:00") : '';
    if (item.eventType) {
      (this.countuptimer) ? clearInterval(this.countuptimer) : '';
      this.t = "00:00:00";
      this.start(item, this.active_order.eventType);
    }
    localStorage.setItem('active_order', JSON.stringify(this.active_order));
    localStorage.setItem(
      this.currentuser.userID + 'focus_order',
      JSON.stringify(this.active_order)
    );
    localStorage.setItem(
      this.currentuser.userID,
      JSON.stringify(this.orders)
    );
    this.getnotes();
  }

  setTimer() {
    setInterval(() => {
      let dt = new Date();
      let hours = dt.getHours();
      let AmOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = (hours % 12) || 12;
      let minutes = dt.getMinutes();
      let getSecunds = dt.getSeconds();
      if (hours == 12 && minutes == 0 && getSecunds == 0 && AmOrPm == 'AM') {
        location.reload();
      }
    }, 1000);
  }


  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType !== 'desktop') {
      $(document).ready(function (ex: any) {
        $('#menuLoginButton').on("touchstart", function (e: any) {
          $('#example').modal('show');
        });
        $('.nav-pills').on("touchstart", function (event: any) {
          event.preventDefault();
          // Toggle dropdown if not already visible:
          if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
            $('.dropdown-toggle').dropdown('toggle');
          } else {
            $('.dropdown-toggle').prev().dropdown("toggle");
          }
        });
        $(document).off("click").on("click", function (e: any) {
          $(".attention").css({ opacity: 1 });
          $('.attention').css('left', e.pageX - 24);
          $('.attention').css('top', e.pageY - 24);
          $('.attention').fadeIn(30).fadeOut(30);
        });
        // $(document).on("touchstart", function (e: any) {
        //   $(".attention").css({ opacity: 1 })
        //   $('.attention').css('left', e.targetTouches[0].pageX - 24);
        //   $('.attention').css('top', e.targetTouches[0].pageY - 24);
        //   $('.attention').fadeIn(30).fadeOut(30);
        // });
      });
    }
    this.setTimer();
    this.dataService.currentMessage.subscribe((item: any) => {
      this.setLocaleDatepicker(item)
    });
    this.activities();
    this.getCategories(this.customerID);
    localStorage.removeItem('orders');
    let user = localStorage.getItem('current_user');
    (user) ? (this.currentuser = JSON.parse(user)) : '';
    (user) ? this.translate.use(this.currentuser.language) : this.translate.use('du');
    (this.currentuser) ? (this.currentuser.language == 'du') ? (this.currentlang = 'Dutch') : (this.currentlang = 'English') : '';
    localStorage.setItem('language', this.currentlang);
    let order = localStorage.getItem('active_order');
    (order) ? (this.active_order = JSON.parse(order)) : '';
  }

  Update_reminder(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = "true";
    if (this.statusForm.value.red_radio == 'true') {
      this.statusForm.patchValue({
        date: {}
      })
    }

    if (this.statusForm.value.red_radio == 'true' && !(Object.keys(this.statusForm.value.date).length > 0) && this.statusForm.value.delete_checkbox == false) {
      this.onSubmit_event();
    } else if (this.statusForm.value.red_radio == 'false' && Object.keys(this.statusForm.value.date).length > 0 && this.statusForm.value.delete_checkbox == false) {
      let date: any;
      if (Object.keys(this.statusForm.value.date).length > 0) {
        let year = this.statusForm.value.date._i.year;
        let month = this.statusForm.value.date._i.month + 1;
        let day = this.statusForm.value.date._i.date;
        date = day + '-' + month + '-' + year;
      }
      else {
        date = this.datePipe.transform(this.statusForm.value.date, 'dd-mm-yyyy');
      }
      // let date=this.datePipe.transform(this.statusForm.value.date,'dd-mm-yyyy');
      let datas = {
        "userID": this.customerID,
        "ordernr": this.reminder_order.orderID,
        "eventID": this.reminder_order.eventID,
        "enddate": date
      }
      // let reminder:any = localStorage.getItem('reminder');
      this.reminder = this.reminder.filter((element: any) => {
        return (element.orderID != datas.ordernr)
      })
      this.apiservice.SetTimerForYellow(datas).subscribe((item: any) => {
        this.modalService.dismissAll();

        this.getorders(this.currentuser.userID, this.workstationid, '');
        let data = [];
        if (this.reminder.length != 0) {
          data = [...this.reminder];
          item.orderID = datas.ordernr;
          data.push(item);
        } else {
          item.orderID = datas.ordernr;
          data.push(item);
        }
        localStorage.setItem('reminder', JSON.stringify(data));
        this.setNotification(data);
      })
    } else {
      this.toastrService.error("Please select only one option");
      // buttonId.disabled =false;
      // console.log( buttonId.disabled)
    }
  }

  activities() {
    this.apiservice.getgreenactivities().subscribe(
      (res: customizing) => {
        if (res?.response) {
          localStorage.setItem("customizing", JSON.stringify(res.response));
          this.customizing = res.response;
          let lang_array = [
            { name: 'Dutch', value: 'du' },
            { name: 'English', value: 'en' },
            { name: 'Spanish', value: 'es' },
          ];
          lang_array.forEach((v: any) => {
            this.customizing.languages.forEach((e: any) => {
              if (e == v.value) {
                this.lang_array.push(v)
              }
            });
          });
          this.attachment_flag = res.response.allow_attachments;
          if (
            res.response.numberOfGreenActivities != '1'
          ) {
            this.allow_greensubcat = true;
          } else {
            this.allow_greensubcat = false;
          }
          if (res.response.showAppTimer == '1') {
            this.timer_flag = true;
          } else {
            this.timer_flag = false;
          }
          if (res.response?.setTimerForOrange == '1') {
            this.showorangetimer = true;
            localStorage.setItem("showorange", 'true');
          } else {
            this.showorangetimer = false;
            localStorage.setItem("showorange", 'false');
          }
          if (res.response?.showChecklist == null) {
            localStorage.setItem("showchecklist", 'false');
          } else {
            this.checklist_flag = res.response?.showChecklist;
            localStorage.setItem("showchecklist", JSON.stringify(res.response.showChecklist));
          }
          (res.response.ordernumber_mask) ? localStorage.setItem("input_mask", res.response.ordernumber_mask) : '';
          (res.response.ordernumber_mask_description) ? localStorage.setItem('mask_descp', res.response.ordernumber_mask_description) : '';
          this.getCategories(this.customerID);
        } else {
        }
      },
      (error: any) => {
        console.log(error)
      }
    );
  }

  getnotes() {
    localStorage.removeItem('notes');
    this.apiservice.getnotes(this.active_order.orderID).subscribe(
      (res: any) => {
        if (res) {
          res.forEach((element: any) => {
            let d = parseInt(element.endTime) * 1000;
            var date = new Date(d);
            let datee =
              date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let month =
              date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1;
            element.day = datee + '-' + month;
            let hour =
              date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min =
              date.getMinutes() < 10
                ? '0' + date.getMinutes()
                : date.getMinutes();
            element.timing = hour + ':' + min;
          });
          localStorage.setItem('notes', JSON.stringify(res));
        } else {
          localStorage.setItem('notes', JSON.stringify(null));
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  navigate_cal() {
    this.current_screen = 'workinghours_flag';
    if (this.orders.length > 0) {
      this.orders.forEach((element: any) => {
        element.focus = false;
      });
    }

    if (this.darkblue_event.length) {
      this.active_order = this.darkblue_event[this.darkblue_event.length - 1];
      this.start(this.active_order, 'darkblue');
    } else {
      this.active_order = undefined;
      let flag: any = {};
      flag.eventType = '';
      this.start(flag, 'grey');
    }
    this.set_flag("workinghours_flag", '');
  }

  async stop(flag: any, color: any, onlydelete: boolean) {
    let datas = {
      eventID: flag?.eventID,
      orderkey: '',
      deleteFromERP: false,
      userID: this.currentuser ? this.currentuser.userID : flag.userID,
      workstationID: this.workstationid,
    };
    if (color == 'green' && onlydelete == true) {
      datas.deleteFromERP = true;
    } else if ((color != 'green' || this.timer_flag == false) && onlydelete == true) {
      datas.deleteFromERP = false;
    } else {
    }
    let order = flag;
    let res: any = await this.apiservice.stopevent(datas).toPromise();
    if (res) {
      if (res.response == 'success') {
        let temp = '';
        var checklist;
        if (this.customizing?.showChecklist != null) {
          await this.customizing?.showChecklist.forEach((element: any) => {
            if (element == 'afterGreenClosed') {
              temp = element;
              checklist = true;
            }
          });
        } else {
          checklist = false;
        }
        (((checklist == true || this.customizing?.showInstructions == 'afterGreenClosed')) && color == 'green' && onlydelete == true) ?
          ((localStorage.setItem("lastflag", "afterGreenClosed"), await this.erpattachment(this.active_order), this.goodsReciptFlag = 'afterGreenClosed', this.getChecklistItems(this.currentuser?.userID, order?.orderID, this.active_order?.mapping_field, temp, checklist, 'afterGreenClosed'))) : '';
        if (color == 'blue') {
          this.reportstatus_flag = false;
        } else if (this.currentuser == undefined) {
        } else {
          if (onlydelete == true) {
            if (this.orders.length == 1) {
              (this.orders[0].orderID == order.orderID) ? (this.orders = [], localStorage.removeItem(this.currentuser.userID)) : '';
            } else {
              let array: any = this.orders.filter((element: any) => {
                return element.orderID !== order.orderID
              });
              this.orders = array;
            }
            localStorage.removeItem('active_order');
            localStorage.setItem(this.currentuser.userID, JSON.stringify(this.orders))
            await this.getorders(this.currentuser.userID, this.workstationid, false);
          }
          localStorage.removeItem('orders');
          localStorage.removeItem(this.currentuser.userID + 'focus_order');
        }
        if (color === 'darkblue' && onlydelete == true) {
          this.darkblue_event = [];
          localStorage.removeItem("darkblueorder")
          if (this.orders.length >= 1) {
            if (this.current_screen != "workinghours_flag") {
              this.current_screen = 'status_screen';
              this.workinghours_flag = false;
              this.lastorder_focus();
              this.set_flag('screen', '');
            }
          } else {
            localStorage.removeItem(this.currentuser.userID)
            this.add_order('s', '', '');
            this.active_order = this.orders[0];
          }
          // this.child.CreateDarkBlue('sd');
        }
      } else {
        this.toastrService.error('Event is not stopped!', 'Error!');
      }
    }
    flag = false;
  }

  async delete_order() {
    if ((this.current_screen != null) && this.current_screen != 'workinghours_flag' || this.active_order?.eventType == 'darkblue') {
      if (this.reportstatus_flag == true) {
        localStorage.removeItem('active_order');
        localStorage.removeItem('orders');
        localStorage.removeItem(this.currentuser.userID + 'focus_order');
        await this.stop(this.report_event, 'blue', true);
        await this.stop(this.active_order, this.active_order.eventType, true);
      } else if (
        this.reportstatus_flag == false &&
        this.active_order != undefined &&
        this.active_order.eventType != ''
      ) {
        localStorage.removeItem('active_order');
        localStorage.removeItem('orders');
        localStorage.removeItem(this.currentuser.userID + 'focus_order');
        await this.stop(this.active_order, this.active_order.eventType, true);
      } else if (
        this.active_order.eventType == '' &&
        this.active_order.focus == true
      ) {
        if (this.orders?.length > 1 || this.active_order.orderID != '') {
          let id = this.active_order.orderID;
          let t = this.orders.filter((item: any) => {
            return (item.orderID != id);
          });
          this.temp_orders = localStorage.getItem(this.currentuser.userID);
          this.temp_orders = JSON.parse(this.temp_orders);
          if (this.temp_orders && this.temp_orders?.length > 0) {
            let k = this.temp_orders.filter((item: any) => {
              return (item.orderID != id);
            });
            localStorage.setItem(this.currentuser.userID, JSON.stringify(k));
            this.temp_orders = localStorage.getItem(this.currentuser.userID);
          }
          this.orders = t;
          if (this.orders?.length == 0 && this.current_screen != 'workinghours_flag') {
            this.add_order('s', '', '')
          }
          localStorage.removeItem('active_order');
          localStorage.removeItem('orders');
          localStorage.removeItem(this.currentuser.userID + 'focus_order');
        }
      } else {
        this.toastrService.warning('Please select an order to close', 'Warning');
      }
      (this.orders.length > 0 && this.current_screen != 'workinghours_flag') ? this.lastorder_focus() : '';
    }
  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // opened(content: any,order:any) {
  // this.statusForm.reset();
  //   this.currentOrder=order
  //   this.getorders(this.currentuser.userID, this.workstationid,content);

  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    let lastlogin: any = localStorage.getItem("LastLogin");
    let greenperday: any = localStorage.getItem("onegreenperday");
    localStorage.clear();
    localStorage.setItem("LastLogin", lastlogin);
    (greenperday) ? localStorage.setItem("onegreenperday", greenperday) : '';
    this.router.navigate([base_routes.LOGIN]);
  }

  async set_flag(status: any, content: any) {
    this.blueOrder = false;
    if (status == 'blue') {
      this.blueOrder = true
    }
    this.clicks = false;
    this.obj = undefined;
    this.isShow = true;
    if (status == 'yellow') {
      this.workingon_flag = false;
      this.screen_status = false;
      this.downtime_flag = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
    } else if (status == 'red') {
      this.workingon_flag = false;
      this.downtime_flag = false;
      this.screen_status = false;
      this.workinghours_flag = false;
      this.unplanned_flag = true;
      this.reports_flag = false;
      this.project_flag = false;
    } else if (status == 'screen') {
      this.workingon_flag = false;
      this.downtime_flag = false;
      this.screen_status = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
      this.greencat_flag = false;
      this.workingon_flag = false;
    } else if (status == 'blue') {
      this.dataService.sendValue(false);
      this.workingon_flag = false;
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = true;
      this.project_flag = false;
    } else if (status == 'workinghours_flag') {
      localStorage.setItem('current_screen', status);
      localStorage.removeItem("active_order");
      this.workingon_flag = false;
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = true;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
    } else if (status == 'green') {
      if (this.allow_greensubcat == true) {
        this.workingon_flag = true;
        this.screen_status = false;
        this.downtime_flag = false;
        this.workinghours_flag = false;
        this.unplanned_flag = false;
        this.reports_flag = false;
        this.project_flag = false;
      } else {
        this.green_eventdata;
        for (let j = 0; j <= this.orders.length - 1; j++) {
          let flag;
          if (
            this.orders[j].eventType == 'green' &&
            this.orders.length > 1 &&
            this.active_order.orderID != this.orders[j].orderID
          ) {
            this.green_eventdata = this.orders[j];
            localStorage.setItem(
              'green_eventdata',
              JSON.stringify(this.green_eventdata)
            );
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (this.green_eventdata) {
          this.open(content);
        } else {
          this.disablegreen = true;
          await this.stop(this.active_order, 'green', false);
          await this.createorder(status, 'working on the order', this.active_order);
          this.green_screen = true;
          // this.reports_flag = true;
          // this.screen_status = false;
        }
      }
    } else {
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = true;
    }
    (status != 'workinghours_flag') ? localStorage.removeItem('current_screen') : '';
  }

  clear_greendata() {
    localStorage.removeItem('green_eventdata');
    this.green_eventdata = undefined;
  }

  async onSubmit_event() {
    this.modalService.dismissAll();
    if (this.activityForm.value.event == 'close') {
      await this.stop(this.green_eventdata, 'green', true);
      (this.active_order.eventType == '') ?
        await this.stop(this.active_order, 'close', false) : '';
      this.green_eventdata = undefined;
      let eventDescp: any = localStorage.getItem("green_subcat");
      eventDescp = JSON.parse(eventDescp);
      (eventDescp) ? eventDescp : eventDescp = "working on the order";
      await this.createorder('green', eventDescp, this.active_order);
      this.green_screen = true;
      if (environment.Deactivate_timer == true) {
        this.start(this.active_order, 'green');
      }
      localStorage.removeItem("green_subcat");
      localStorage.removeItem("green_eventdata");
      this.workingon_flag = false;
    } else if (this.activityForm.value.event == 'yellow') {
      this.workingon_flag = false;
      this.screen_status = false;
      this.downtime_flag = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
    } else {
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = true;
      this.reports_flag = false;
      this.project_flag = false;
    }
    this.activityForm.reset({});
  }
  previousScreen() {
    this.screen_status = this.obj?.screen_status
    this.downtime_flag = this.obj.downtime_flag;
    this.workinghours_flag = this.obj.workinghours_flag;
    this.unplanned_flag = this.obj.unplanned_flag;
    this.reports_flag = this.obj.reports_flag;
    this.project_flag = this.obj.project_flag;
  }
  setnavigation() {
    if (this.reports_flag == false) {
      this.obj = {
        'screen_status': this.screen_status,
        'downtime_flag': this.downtime_flag,
        'workinghours_flag': this.workinghours_flag,
        'unplanned_flag': this.unplanned_flag,
        'reports_flag': this.reports_flag,
        'project_flag': this.project_flag
      }
    }
    this.screen_status = false;
    this.downtime_flag = false;
    this.workinghours_flag = false;
    this.unplanned_flag = false;
    this.reports_flag = true;
    this.project_flag = false;
  }

}
