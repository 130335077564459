<!--Instructions Modal-->

<ng-template #compactchecklist let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            *ngIf="
              !isShow || this.attachments?.length == 0 || !this.attachments
            "
            type="button"
            class="btn print-btn"
            (click)="
              addCompact(submitButton);
            "
            #submitButton
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', ''); isD = true"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items); isD = false"
                >
                  {{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container>
            <form
              novalidate
              [formGroup]="checkListFormGroup"
            >
              <div class="mb-5" *ngFor="let list of checkListItems">
                <h1 class="compact-title">{{ list.title }}</h1>
                <div class="maint_container_list">
                  <ng-container *ngFor="let datas of list.header">
                    <div class="maint_container_list_details mb-2">
                      <ng-container [ngSwitch]="datas.fieldType">
                        <label
                          [attr.for]="datas.fieldName"
                          class="p-2 titelfont"
                          style="color: #24597a"
                          *ngSwitchCase="'label'"
                          >{{ datas?.value }}</label
                        >
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngFor="let item of list.body">
                  <div class="maint_container_list">
                    <ng-container *ngFor="let data of item">
                      <div class="maint_container_list_details mb-2">
                        <ng-container [ngSwitch]="data.fieldType">
                          <label
                            [attr.for]="data.fieldName"
                            class="p-2 titelfont"
                            style="color: #24597a"
                            *ngSwitchCase="'label'"
                            >{{ data?.value }}</label
                          >

                          <input
                            *ngSwitchCase="'int'"
                            class="form-control titelfont"
                            [formControlName]="data.fieldName"
                            appMinmax
                            [id]="data.fieldName"
                            type="number"
                            [min]="data.min !== undefined ? data.min : 0"
                            [max]="data.max !== undefined ? data.max : ''"
                            [value]="data?.value"
                            [placeholder]="
                              data?.placeholder ? data?.placeholder : ''
                            "
                          />
                          <input
                            *ngSwitchCase="'checkbox'"
                            class="form-check-input titelfont"
                            [formControlName]="data.fieldName"
                            [id]="data.fieldName"
                            type="checkbox"
                            [checked]="data?.value"
                          />

                          <textarea
                          *ngSwitchCase="'textarea'"
                           class="form-control" 
                          [formControlName]="data.fieldName"
                          [id]="data.fieldName"
                          type="text"
                          [value]="data?.value"
                          cols="2" rows="2"></textarea>

                          <mat-select
                          class="form-control"
                          *ngSwitchCase="'select'"
                          [(ngModel)]="data.value"
                          [formControlName]="data.fieldName"
                        >
                          <ng-container *ngFor="let list of data.options">
                            <mat-option [value]="list.id" *ngIf="list.id != ''">
                              {{ list.description }}
                            </mat-option>
                          </ng-container>
                        </mat-select>

                          <input *ngSwitchCase="'text'" class="form-control" [formControlName]="data.fieldName"
                          [id]="data.fieldName" type="text" [value]="data?.value">

                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <br />
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #suncircle let-modal>
  <div  class="instructions-popup">
    <div class="modal-header align-items-start  ">
      <div
      class="d-flex  justify-content-between"
      style="width: 100%"
    >
      <!-- <h2 class="modal-title" style="color: #24597a">
        {{ "HOME.INSTRUCTDOCUMENT" | translate }}
      </h2> -->
      <!-- <div class="row"> -->
        <div class="w-50 d-flex flex-column ">
<label for="" *ngIf="instructionsItems?.prodOrderNo"> <strong>Prod.-ordernr:</strong> {{instructionsItems?.prodOrderNo}}</label>
<label for="" *ngIf="instructionsItems?.customerInfo?.Sell_to_Customer_Name"> <strong>Klantnaam:</strong> {{instructionsItems?.customerInfo?.Sell_to_Customer_Name}}</label>
<label for="" *ngIf="instructionsItems?.dueDate"> <strong>Verzenddag:</strong> {{instructionsItems?.dueDate}}</label>

<div *ngIf="instructionsItems?.status == 'Finished'">
<span class="warning order_button" > Order is reeds verwerkt!</span>
</div>
        </div>
        <div class="w-50 d-flex flex-column ">
<label for="" *ngIf="instructionsItems?.qty && instructionsItems?.qty !=''"><strong>Aantal:</strong> {{instructionsItems?.qty}}</label>
<label for="" *ngIf="instructionsItems?.location && instructionsItems?.location !=''"> <strong>Locatie doek:</strong> {{instructionsItems?.location}}</label>
        </div>

      <!-- </div> -->
    </div>
      <div class="header-actions">
        <button
        type="button"

        class="btn prints-btn red-warning "

      >
        Verzendlabel
      </button>
        <button
          type="button"
          class="btn prints-btn blue-highlight "
         
        >
        Print sticker
        </button>
        <button
          type="button"
          class="btn back-btn gray-light "
          (click)="modal.dismiss('Cross clicked')"
        >
        {{ "HOME.CLOSEBUTTON" | translate }}
        </button>
      </div>
    </div>
    <div class=" modal_padding">
      <div class="d-flex">
        <div class="instruction_first_section">
           <h1 class="title font-weight-qr ">{{instructionsItems?.materialDescription}}</h1>
        </div>
        <div class="">
           <h1 class="title font-weight-qr ">{{instructionsItems?.orderID}}</h1>
        </div>
      </div>
      <div  [ngClass]="[ instructionsItems?.QRcode ?'d-flex gap-2 ':'']"  >  
      <div [ngClass]="[ instructionsItems?.QRcode ?'colum_list2':'colum_list3']">
        <div class="card_input_block" *ngFor="let list of instructionsItems?.articleFeatures?.value">
          <div class="d-flex align-items-center gap-1 " >
            <div class="w-50">
              <label for="">{{list.Name}}</label>
            </div>
            <div class="">
              <label for="">:{{list.Value}}</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.instructionsItems?.QRcode">
        <h6 class="qr_info_details" *ngIf="instructionsItems?.karLocation"><span class="font-weight-qr "> Locatie kar:</span> {{instructionsItems?.karLocation}}</h6>
        <qrcode [qrdata]="this.instructionsItems?.QRcode" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column footer_padding " *ngFor="let data of instructionsItems?.productionBOM">
    <label *ngIf="data?.Name" for=""><strong>{{data?.Name}}</strong></label>
      <label for="">Materiaal</label>
      <div *ngFor="let list of data?.Value">
        <label *ngIf="list?.displayQty" for="">{{list?.displayQty}}</label>
        <label *ngIf="list?.Description" for="">{{list?.Description}}</label>
        <label *ngIf="list?.displayLength" for="">{{list?.displayLength}}</label>
        <label *ngIf="list?.Unit_of_Measure_Code" for="">{{list?.Unit_of_Measure_Code}}</label>
      </div>
    </div>
  

</div>
</ng-template>
<ng-template #checklist let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            *ngIf="
              !isShow || this.attachments?.length == 0 || !this.attachments
            "
            type="button"
            class="btn print-btn"
            #submitButton
            (click)="
              addChecklist('',submitButton);
              !checkfields
                ? modal.dismiss('Cross click')
                : checkfields?.length > 0
                ? modal.dismiss('Cross click')
                : ''
            "
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="instructions-content" *ngIf="workstationid == 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', ''); isD = true"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items); isD = false"
                >
                  {{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container *ngIf="!isShow">
            <form
              *ngIf="isD"
              novalidate
              [formGroup]="checkListFormGroup"
            >
              <div *ngFor="let prop of checkListItems">
                <div class="row mt-3">
                  <label
                    [attr.for]="prop.fieldName"
                    class="col-sm-4 d-flex p-2"
                    style="color: #24597a"
                    >{{ prop.fieldName }}</label
                  >

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input
                        type="text"
                        hidden="true"
                        formControlName="details"
                      />
                      <textarea
                        *ngSwitchCase="'textarea'"
                        [ngClass]="{ textarea: prop.textarea }"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        name=""
                        id=""
                        cols="1"
                        [rows]="prop.addrows"
                      ></textarea>

                      <input
                        *ngSwitchCase="'text'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [type]="prop.fieldType"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'int'"
                        appMinmax
                        [min]="prop.min !== undefined ? prop.min : 0"
                        [max]="prop.max !== undefined ? prop.max : ''"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'float'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'string'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="varchar"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'file'"
                        type="file"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />
                      <input
                        *ngSwitchCase="'checkbox'"
                        [ngClass]="[
                          prop.protected === true ||
                          prop.protected == 'true' ||
                          +prop.protected === 1
                            ? 'active'
                            : 'in-active'
                        ]"
                        class="form-check-input"
                        [checked]="prop.value"
                        type="checkbox"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'email'"
                        type="email"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <div class="input-group" *ngSwitchCase="'date'">
                        <input
                          class="form-control dateicon"
                          matInput
                          [matDatepicker]="dp"
                          (click)="dp.open()"
                          [id]="prop.key"
                          [formControlName]="prop.fieldName"
                        />
                        <mat-datepicker-toggle
                          class="date"
                          matSuffix
                          [for]="dp"
                          id="datepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input
                        *ngSwitchCase="'datetime'"
                        type="datetime"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'reset'"
                        type="reset"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'tel'"
                        type="tel"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'url'"
                        type="url"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <mat-select
                        class="form-control"
                        placeholder="{{
                          prop['multi-select'] &&
                          prop.value[0] == '' &&
                          prop.options[0].id == ''
                            ? prop.options[0].description
                            : !prop['multi-select'] &&
                              prop.value == '' &&
                              prop.options[0].id == ''
                            ? prop.options[0].description
                            : ''
                        }}"
                        *ngSwitchCase="'select'"
                        [(value)]="prop.value"
                        multiple="{{ prop['multi-select'] }}"
                        [formControlName]="prop.fieldName"
                      >
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id != ''">
                            {{ list.description }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <br />
            </form>
          </ng-container>

          <ng-container *ngIf="!isD">
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader" ></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="instructions-content" *ngIf="workstationid != 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', '')"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items)"
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container>
            <form
              novalidate
              [formGroup]="checkListFormGroup"
            >
              <div *ngFor="let prop of checkListItems">
                <div class="row mt-3">
                  <label
                    [attr.for]="prop.fieldName"
                    class="col-sm-4 d-flex p-2"
                    style="color: #24597a"
                    >{{ prop.fieldName }}</label
                  >

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input
                        type="text"
                        hidden="true"
                        formControlName="details"
                      />
                      <textarea
                        *ngSwitchCase="'textarea'"
                        [ngClass]="{ textarea: prop.textarea }"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        name=""
                        id=""
                        cols="1"
                        [rows]="prop.addrows"
                      ></textarea>

                      <input
                        *ngSwitchCase="'text'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [type]="prop.fieldType"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'int'"
                        appMinmax
                        [min]="prop.min !== undefined ? prop.min : 0"
                        [max]="prop.max !== undefined ? prop.max : ''"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'float'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'string'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="varchar"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'file'"
                        type="file"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />
                      <input
                        *ngSwitchCase="'checkbox'"
                        [ngClass]="[
                          prop.protected === true ||
                          prop.protected == 'true' ||
                          +prop.protected === 1
                            ? 'active'
                            : 'in-active'
                        ]"
                        class="form-check-input"
                        [checked]="prop.value"
                        type="checkbox"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'email'"
                        type="email"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <div class="input-group" *ngSwitchCase="'date'">
                        <input
                          class="form-control dateicon"
                          matInput
                          [matDatepicker]="dp"
                          (click)="dp.open()"
                          [id]="prop.key"
                          [formControlName]="prop.fieldName"
                        />
                        <mat-datepicker-toggle
                          class="date"
                          matSuffix
                          [for]="dp"
                          id="datepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input
                        *ngSwitchCase="'datetime'"
                        type="datetime"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'reset'"
                        type="reset"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'tel'"
                        type="tel"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'url'"
                        type="url"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <mat-select
                        class="form-control"
                        placeholder="{{
                          prop['multi-select'] &&
                          prop.value[0] == '' &&
                          prop.options[0].id == ''
                            ? prop.options[0].description
                            : !prop['multi-select'] &&
                              prop.value == '' &&
                              prop.options[0].id == ''
                            ? prop.options[0].description
                            : ''
                        }}"
                        *ngSwitchCase="'select'"
                        [(value)]="prop.value"
                        multiple="{{ prop['multi-select'] }}"
                        [formControlName]="prop.fieldName"
                      >
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id != ''">
                            {{ list.description }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <br />
            </form>
          </ng-container>

          <ng-container>
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading" ></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader"></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #checklisttop let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross clicked'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            *ngIf="
              !isShow || this.attachments?.length == 0 || !this.attachments
            "
            type="button"
            class="btn print-btn"
            #submitButton
            (click)="aaddChecklist('',submitButton); modal.dismiss('Cross clicked')"
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross clicked')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="instructions-content" *ngIf="workstationid == 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', ''); isD = true"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items); isD = false"
                >
                  {{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container *ngIf="!isShow">
            <form
              *ngIf="isD"
              novalidate
              [formGroup]="checklistFormList"
            >
              <div *ngFor="let prop of check_List">
                <div class="row mt-3">
                  <label
                    [attr.for]="prop.fieldName"
                    class="col-sm-4 d-flex p-2"
                    style="color: #24597a"
                    >{{ prop.fieldName }}</label
                  >

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input
                        type="text"
                        hidden="true"
                        formControlName="details"
                      />
                      <textarea
                        *ngSwitchCase="'textarea'"
                        [ngClass]="{ textarea: prop.textarea }"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        name=""
                        id=""
                        cols="1"
                        [rows]="prop.addrows"
                      ></textarea>

                      <input
                        *ngSwitchCase="'text'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [type]="prop.fieldType"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'int'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        appMinmax
                        [min]="prop.min !== undefined ? prop.min : 0"
                        [max]="prop.max !== undefined ? prop.max : ''"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'float'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'string'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="varchar"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'file'"
                        type="file"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />
                      <input
                        *ngSwitchCase="'checkbox'"
                        class="form-check-input"
                        [checked]="prop.value"
                        [ngClass]="[
                          prop.protected === true ||
                          prop.protected == 'true' ||
                          +prop.protected === 1
                            ? 'active'
                            : 'in-active'
                        ]"
                        type="checkbox"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'email'"
                        type="email"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <div class="input-group" *ngSwitchCase="'date'">
                        <input
                          class="form-control dateicon"
                          matInput
                          [matDatepicker]="dp"
                          (click)="dp.open()"
                          [id]="prop.key"
                          [formControlName]="prop.fieldName"
                        />
                        <mat-datepicker-toggle
                          class="date"
                          matSuffix
                          [for]="dp"
                          id="datepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input
                        *ngSwitchCase="'datetime'"
                        type="datetime"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'reset'"
                        type="reset"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'tel'"
                        type="tel"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'url'"
                        type="url"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <mat-select
                        class="form-control"
                        placeholder="{{
                          prop['multi-select'] &&
                          prop.value[0] == '' &&
                          prop.options[0].id == ''
                            ? prop.options[0].description
                            : !prop['multi-select'] &&
                              prop.value == '' &&
                              prop.options[0].id == ''
                            ? prop.options[0].description
                            : ''
                        }}"
                        *ngSwitchCase="'select'"
                        [(value)]="prop.value"
                        multiple="{{ prop['multi-select'] }}"
                        [formControlName]="prop.fieldName"
                      >
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id != ''">
                            {{ list.description }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <br />
            </form>
          </ng-container>

          <ng-container *ngIf="!isD">
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader"></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>

              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
            </div>
          </ng-container>
        </div>
      </div>
      <div class="instructions-content" *ngIf="workstationid != 98">
        <div class="instructions-content-left">
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->

            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', '')"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items)"
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <ng-container>
            <form
              novalidate
              [formGroup]="checklistFormList"
            >
              <div *ngFor="let prop of check_List">
                <div class="row mt-3">
                  <label
                    [attr.for]="prop.fieldName"
                    class="col-sm-4 d-flex p-2"
                    style="color: #24597a"
                    >{{ prop.fieldName }}</label
                  >

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input
                        type="text"
                        hidden="true"
                        formControlName="details"
                      />
                      <textarea
                        *ngSwitchCase="'textarea'"
                        [ngClass]="{ textarea: prop.textarea }"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        name=""
                        id=""
                        cols="1"
                        [rows]="prop.addrows"
                      ></textarea>

                      <input
                        *ngSwitchCase="'text'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [type]="prop.fieldType"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'int'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        appMinmax
                        [min]="prop.min !== undefined ? prop.min : 0"
                        [max]="prop.max !== undefined ? prop.max : ''"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'float'"
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'string'"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="varchar"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'file'"
                        type="file"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />
                      <input
                        *ngSwitchCase="'checkbox'"
                        class="form-check-input"
                        [ngClass]="[
                          prop.protected === true ||
                          prop.protected == 'true' ||
                          +prop.protected === 1
                            ? 'active'
                            : 'in-active'
                        ]"
                        [checked]="prop.value"
                        type="checkbox"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'email'"
                        type="email"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <div class="input-group" *ngSwitchCase="'date'">
                        <input
                          class="form-control dateicon"
                          matInput
                          [matDatepicker]="dp"
                          (click)="dp.open()"
                          [id]="prop.key"
                          [formControlName]="prop.fieldName"
                        />
                        <mat-datepicker-toggle
                          class="date"
                          matSuffix
                          [for]="dp"
                          id="datepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input
                        *ngSwitchCase="'datetime'"
                        type="datetime"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'reset'"
                        type="reset"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'tel'"
                        type="tel"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'url'"
                        type="url"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <mat-select
                        class="form-control"
                        placeholder="{{
                          prop['multi-select'] &&
                          prop.value[0] == '' &&
                          prop.options[0].id == ''
                            ? prop.options[0].description
                            : !prop['multi-select'] &&
                              prop.value == '' &&
                              prop.options[0].id == ''
                            ? prop.options[0].description
                            : ''
                        }}"
                        *ngSwitchCase="'select'"
                        [(value)]="prop.value"
                        multiple="{{ prop['multi-select'] }}"
                        [formControlName]="prop.fieldName"
                      >
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id != ''">
                            {{ list.description }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <br />
            </form>
          </ng-container>

          <ng-container>
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader" ></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #onlyChecklist let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            type="button"
            class="btn print-btn"
            #submitButton
            (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li>
              <a class="bold" (click)="setOption('checklist', '')">{{
                "HOME.CHECKLIST_LABEL" | translate
              }}</a>
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items); isD = false"
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
          <form novalidate [formGroup]="checkListFormGroup">
            <div *ngFor="let prop of checkListItems">
              <div class="row mt-3">
                <label
                  [attr.for]="prop.fieldName"
                  class="col-sm-4 d-flex p-2"
                  style="color: #24597a"
                  >{{ prop.fieldName }}</label
                >

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input
                      type="text"
                      hidden="true"
                      formControlName="details"
                    />
                    <textarea
                      *ngSwitchCase="'textarea'"
                      [ngClass]="{ textarea: prop.textarea }"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                      name=""
                      id=""
                      cols="1"
                      [rows]="prop.addrows"
                    ></textarea>
                    <input
                      *ngSwitchCase="'text'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [type]="prop.fieldType"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'int'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      appMinmax
                      [min]="prop.min !== undefined ? prop.min : 0"
                      [max]="prop.max !== undefined ? prop.max : ''"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'float'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'string'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="varchar"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'file'"
                      type="file"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />
                    <input
                      *ngSwitchCase="'checkbox'"
                      [ngClass]="[
                        prop.protected === true ||
                        prop.protected == 'true' ||
                        +prop.protected === 1
                          ? 'active'
                          : 'in-active'
                      ]"
                      class="form-check-input"
                      [checked]="prop.value"
                      type="checkbox"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'email'"
                      type="email"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <div class="input-group" *ngSwitchCase="'date'">
                      <input
                        class="form-control dateicon"
                        matInput
                        [matDatepicker]="dp"
                        (click)="dp.open()"
                        [id]="prop.key"
                        [formControlName]="prop.fieldName"
                      />
                      <mat-datepicker-toggle
                        class="date"
                        matSuffix
                        [for]="dp"
                        id="datepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input
                      *ngSwitchCase="'datetime'"
                      type="datetime"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'reset'"
                      type="reset"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'tel'"
                      type="tel"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'url'"
                      type="url"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <mat-select
                      class="form-control"
                      placeholder="{{
                        prop['multi-select'] &&
                        prop.value[0] == '' &&
                        prop.options[0].id == ''
                          ? prop.options[0].description
                          : !prop['multi-select'] &&
                            prop.value == '' &&
                            prop.options[0].id == ''
                          ? prop.options[0].description
                          : ''
                      }}"
                      *ngSwitchCase="'select'"
                      [(value)]="prop.value"
                      multiple="{{ prop['multi-select'] }}"
                      [formControlName]="prop.fieldName"
                    >
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id != ''">
                          {{ list.description }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </div>
                </ng-container>
              </div>
            </div>
            <br />
          </form>

          <ng-container *ngIf="!isD">
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader"></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #instructions let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            type="button"
            class="btn print-btn"
            [ngClass]="{ 'disabled-btn disabled': true }"
            #submitButton
            (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="
                customizing?.showInstructions != null ||
                customizing?.showInstructions == goodsRecipt ||
                  goodsRecipt == ''
              "
            >
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('instructions', '')"
                >{{ "HOME.INSTRUCTIONS_LABEL" | translate }}</a
              >
            </li>
            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items)"
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="projectbody instructions-content-right-desktop">
            <div class="row mt-3" *ngFor="let item of instructionsItems">
              <label [ngClass]="getClass(layoutValues[0])" class="instLabel">{{
                item?.fieldDescription
              }}</label>
              <label
              [ngClass]="getClass(layoutValues[1])"
                class=" instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value == null"
                ><input class="form-check-input" type="checkbox" disabled
              /></label>
              <label
              [ngClass]="getClass(layoutValues[0])"
                class=" instLabel"
                *ngIf="item.fieldType == 'text' && item?.value == null"
              ></label>
              <label
                class="col-sm-4 instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value != null"
                ><input
                  class="form-check-input"
                  type="checkbox"
                  checked
                  disabled
              /></label>
              <label [ngClass]="getClass(layoutValues[1])"
                class=" instLabel value-container"
                *ngIf="item.fieldType == 'text' && item?.value != null"
                >{{ item?.value }}</label
              >
            </div>
          <div
            class="row h-100"
            *ngIf="
              selectedOption != 'instructions' &&
              selectedOption != 'checklist' &&
              attachments
            "
          >
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe>
            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
              <div class="loader"></div>
              <!-- <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe> -->

              <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
              <div class="error-message" style="display: none;">
                Unable to load the requested content. Please try again later.
            </div>
              <iframe  class="iframeTou" frameborder="0"></iframe>
            </ng-container>
            <!-- <iframe (load)="loadingPDF()" *ngIf="src" [src]="src" class="iframe" frameborder="0"></iframe> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checklistinstructions let-modal>
  <div class="instructions-popup">
    <div class="modal-header" *ngIf="!isShow">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            type="button"
            class="btn print-btn"
            #submitButton
            (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-header" *ngIf="isShow">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
            type="button"
            class="btn print-btn"
            [ngClass]="{ 'disabled-btn disabled': true }"
            #submitButton
            (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
          >
            {{ "HOME.CONFIRMBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="
                customizing?.showInstructions != null ||
                customizing?.showInstructions == goodsRecipt ||
                  goodsRecipt == ''
              "
            >
              <a
                [ngClass]="isShow ? 'chacive' : ''"
                class="checklisinstruction bold"
                (click)="setOption('instructions', ''); isShow = true"
                >{{ "HOME.INSTRUCTIONS_LABEL" | translate }}</a
              >
            </li>
            <li *ngIf="workstationid == 98">
              <a
                class="checklisinstruction bold"
                [ngClass]="!isShow ? 'chacive' : ''"
                (click)="
                  setOption('checklist', '');
                  isD = true;
                  this.secondOrderId = ''
                "
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <li *ngIf="workstationid != 98">
              <a
                class="checklisinstruction bold"
                [ngClass]="!isShow ? 'chacive' : ''"
                (click)="
                  setOption('checklist', '');
                  isShow = false;
                  this.secondOrderId = ''
                "
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>

            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="setOption(items.document_type, items); isD = false"
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
        <div
          *ngIf="!isShow"
          class="projectbody instructions-content-right-desktop"
        >
          <form
            *ngIf="isD"
            novalidate
            [formGroup]="checkListFormGroup"
          >
            <div *ngFor="let prop of checkListItems">
              <div class="row mt-3">
                <label
                  [attr.for]="prop.fieldName"
                  class="col-sm-4 d-flex p-2"
                  style="color: #24597a"
                  >{{ prop.fieldName }}</label
                >

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input
                      type="text"
                      hidden="true"
                      formControlName="details"
                    />
                    <textarea
                      *ngSwitchCase="'textarea'"
                      [ngClass]="{ textarea: prop.textarea }"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                      name=""
                      id=""
                      cols="1"
                      [rows]="prop.addrows"
                    ></textarea>
                    <input
                      *ngSwitchCase="'text'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [type]="prop.fieldType"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'int'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      appMinmax
                      [min]="prop.min !== undefined ? prop.min : 0"
                      [max]="prop.max !== undefined ? prop.max : ''"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'float'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'string'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="varchar"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'file'"
                      type="file"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />
                    <input
                      *ngSwitchCase="'checkbox'"
                      [ngClass]="[
                        prop.protected === true ||
                        prop.protected == 'true' ||
                        +prop.protected === 1
                          ? 'active'
                          : 'in-active'
                      ]"
                      class="form-check-input"
                      [checked]="prop.value"
                      type="checkbox"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'email'"
                      type="email"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <div class="input-group" *ngSwitchCase="'date'">
                      <input
                        class="form-control dateicon"
                        matInput
                        [matDatepicker]="dp"
                        (click)="dp.open()"
                        [id]="prop.key"
                        [formControlName]="prop.fieldName"
                      />
                      <mat-datepicker-toggle
                        class="date"
                        matSuffix
                        [for]="dp"
                        id="datepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input
                      *ngSwitchCase="'datetime'"
                      type="datetime"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'reset'"
                      type="reset"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'tel'"
                      type="tel"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'url'"
                      type="url"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <mat-select
                      class="form-control"
                      placeholder="{{
                        prop['multi-select'] &&
                        prop.value[0] == '' &&
                        prop.options[0].id == ''
                          ? prop.options[0].description
                          : !prop['multi-select'] &&
                            prop.value == '' &&
                            prop.options[0].id == ''
                          ? prop.options[0].description
                          : ''
                      }}"
                      *ngSwitchCase="'select'"
                      [(value)]="prop.value"
                      multiple="{{ prop['multi-select'] }}"
                      [formControlName]="prop.fieldName"
                    >
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id != ''">
                          {{ list.description }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </div>
                </ng-container>
              </div>
            </div>
            <br />
          </form>

          <ng-container *ngIf="!isD">
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
                <div class="loader"></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="isShow"
          class="projectbody instructions-content-right-desktop"
        >
        
            <div class="row mt-3" *ngFor="let item of instructionsItems">
              <label [ngClass]="getClass(layoutValues[0])" class="instLabel">{{
                item?.fieldDescription
              }}</label>
              <label
              [ngClass]="getClass(layoutValues[1])"
                class="instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value == null"
                ><input class="form-check-input" type="checkbox" disabled
              /></label>
              <label
              [ngClass]="getClass(layoutValues[0])"
                class=" instLabel"
                *ngIf="item.fieldType == 'text' && item?.value == null"
              ></label>
              <label
                class="col-sm-4 instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value != null"
                ><input
                  class="form-check-input"
                  type="checkbox"
                  checked
                  disabled
              /></label>
              <label
              [ngClass]="getClass(layoutValues[1])"
                class="instLabel value-container"
                *ngIf="item.fieldType == 'text' && item?.value != null"
                >{{ item?.value }}</label
              >
            </div>
          
          <div
            class="row h-100"
            *ngIf="
              selectedOption != 'instructions' &&
              selectedOption != 'checklist' &&
              attachments
            "
          >
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe>
            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <div class="loader"></div>
              <!-- <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe> -->
              <div class="error-message" style="display: none;">
                Unable to load the requested content. Please try again later.
            </div>
              <iframe  class="iframeTou" frameborder="0"></iframe>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #topinstructionchecklist let-modal>
  <div class="instructions-popup" style="overflow: auto">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div class="header-actions">
          <button
            type="button"
            *ngIf="customizing?.triggerPrint != null"
            class="btn prints-btn"
            (click)="postPrintDetails('0',false)"
          >
            {{ "HOME.PRINTBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn back-btn"
            (click)="modal.dismiss('Cross click'); resetForm()"
          >
            {{ "HOME.CANCELBUTTON" | translate }}
          </button>
          <button
          [disabled]="+customizing?.allow_cancelChecklist === 0"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div class="instructions-content justify-content-between">
        <div
          class="instructions-content-left instructions-content-right-desktop-deep"
        >
          <ul>
            <li
              *ngIf="
                customizing?.showInstructions != null ||
                customizing?.showInstructions == goodsRecipt ||
                  goodsRecipt == ''
              "
            >
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('instructions', '')"
                >{{ "HOME.INSTRUCTIONS_LABEL" | translate }}</a
              >
            </li>

            <li *ngFor="let items of attachments">
              <a
                [ngClass]="{
                  bold: selectedOption == items.document_type
                }"
                (click)="setOption(items.document_type, items)"
                >{{ items.document_type }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="projectbody instructions-content-right-desktop instructions-content-right-desktop-deep"
        >
          <div class="modal-header modal-header-acn">
            <div
              class="d-flex align-items-center justify-content-between"
              style="width: 100%"
            >
              <h2 class="modal-title" style="color: #24597a"></h2>
              <div class="header-actions">
                <button
                  type="button"
                  class="btn print-btn"
                  [ngClass]="{ 'disabled-btn disabled': true }"
                  #submitButton
                  (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
                >
                  {{ "HOME.CONFIRMBUTTON" | translate }}
                </button>
              </div>
            </div>
          </div>

            <div class=" row mt-3" *ngFor="let item of instructionsItems">
              <label [ngClass]="getClass(layoutValues[0])" class=" instLabel">{{
                item?.fieldDescription
              }}</label>
              <label 
              [ngClass]="getClass(layoutValues[1])"
                class=" instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value == null"
                ><input class="form-check-input" type="checkbox" disabled
              /></label>
              <label
              [ngClass]="getClass(layoutValues[0])"
                class="instLabel"
                *ngIf="item.fieldType == 'text' && item?.value == null"
              ></label>
              <label
                class="col-sm-4 instLabel"
                *ngIf="item.fieldType == 'checkbox' && item?.value != null"
                ><input
                  class="form-check-input"
                  type="checkbox"
                  checked
                  disabled
              /></label>
              <label
              [ngClass]="getClass(layoutValues[1])"
                class="instLabel value-container"
                *ngIf="item.fieldType == 'text' && item?.value != null"
                >{{ item?.value }}</label
              >
            </div>
          
          <div
            class="row h-100"
            *ngIf="
              selectedOption != 'instructions' &&
              selectedOption != 'checklist' &&
              attachments
            "
          >
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe>
            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <div class="loader"></div>
              <!-- <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe> -->
              <div class="error-message" style="display: none;">
                Unable to load the requested content. Please try again later.
            </div>
              <iframe  class="iframeTou" frameborder="0"></iframe>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-header p-0"></div>
    <div class="modal-body">
      <div class="instructions-content justify-content-between">
        <div
          class="instructions-content-left instructions-content-right-desktop-deep"
        >
          <ul>
            <!-- <li
              *ngIf="customizing?.showInstructions != null || (customizing?.showInstructions == goodsRecipt || goodsRecipt == '')">
              <a [ngClass]="{
                bold: selectedOption == 'instructions'
              }" (click)="setOption('instructions','')" *ngIf="showInstructions">{{ "HOME.INSTRUCTIONS_LABEL" |
                translate }}</a>
            </li> -->
            <li>
              <a
                [ngClass]="{
                  bold: true
                }"
                (click)="setOption('checklist', '')"
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>
            <li *ngFor="let items of attachments">
              <a
                [ngClass]="{
                  bold: selectedOption == items.document_type
                }"
                (click)="setOption(items.document_type, items)"
                >{{ items.document_type }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="projectbody instructions-content-right-desktop instructions-content-right-desktop-deep"
        >
          <div class="modal-header modal-header-acn">
            <div
              class="d-flex align-items-center justify-content-end"
              style="width: 100%"
            >
              <!-- <h2 class="modal-title" style="color: #24597A;">{{ "HOME.INSTRUCTDOCUMENT" | translate }}</h2> -->
              <div class="header-actions">
                <!-- <button type="button" *ngIf="customizing?.triggerPrint != null" class="btn prints-btn"
                  (click)="postPrintDetails('0')"> {{ "HOME.PRINTBUTTON" | translate }}</button>
                <button type="button" class="btn back-btn" (click)="modal.dismiss('Cross click');resetForm()"> {{
                  "HOME.CANCELBUTTON" | translate
                  }}</button> -->
                <button
                  type="button"
                  class="btn print-btn"
                  #submitButton
                  (click)="addChecklist('',submitButton)"
                >
                  {{ "HOME.CONFIRMBUTTON" | translate }}
                </button>
                <!-- <button type="button" class="btn-close" aria-label="Close"
                  (click)="modal.dismiss('Cross click')"></button> -->
              </div>
            </div>
          </div>
          <form
            novalidate
            [formGroup]="checkListFormGroup"
          >
            <div *ngFor="let prop of checkListItems">
              <div class="row mt-3">
                <label
                  [attr.for]="prop.fieldName"
                  class="col-sm-4 d-flex p-2"
                  style="color: #24597a"
                  >{{ prop.fieldName }}</label
                >

                <ng-container [ngSwitch]="prop.fieldType">
                  <div class="col-sm-8">
                    <input
                      type="text"
                      hidden="true"
                      formControlName="details"
                    />
                    <textarea
                      *ngSwitchCase="'textarea'"
                      [ngClass]="{ textarea: prop.textarea }"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                      name=""
                      id=""
                      cols="1"
                      [rows]="prop.addrows"
                    ></textarea>

                    <input
                      *ngSwitchCase="'text'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      [type]="prop.fieldType"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'int'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      appMinmax
                      [min]="prop.min !== undefined ? prop.min : 0"
                      [max]="prop.max !== undefined ? prop.max : ''"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'float'"
                      class="form-control"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="number"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'string'"
                      class="form-control"
                      [formControlName]="prop.fieldName"
                      [id]="prop.fieldName"
                      type="varchar"
                      [value]="prop?.value"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'file'"
                      type="file"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />
                    <input
                      *ngSwitchCase="'checkbox'"
                      [ngClass]="[
                        prop.protected === true ||
                        prop.protected == 'true' ||
                        +prop.protected === 1
                          ? 'active'
                          : 'in-active'
                      ]"
                      class="form-check-input"
                      [checked]="prop.value"
                      type="checkbox"
                      [id]="prop.fieldName"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'email'"
                      type="email"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <div class="input-group" *ngSwitchCase="'date'">
                      <input
                        class="form-control dateicon"
                        matInput
                        [matDatepicker]="dp"
                        (click)="dp.open()"
                        [id]="prop.key"
                        [formControlName]="prop.fieldName"
                      />
                      <mat-datepicker-toggle
                        class="date"
                        matSuffix
                        [for]="dp"
                        id="datepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <input
                      *ngSwitchCase="'datetime'"
                      type="datetime"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                    />

                    <input
                      *ngSwitchCase="'reset'"
                      type="reset"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'tel'"
                      type="tel"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <input
                      *ngSwitchCase="'url'"
                      type="url"
                      [id]="prop.fieldName"
                      [value]="prop?.value"
                      [formControlName]="prop.fieldName"
                      [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                    />

                    <mat-select
                      class="form-control"
                      placeholder="{{
                        prop['multi-select'] &&
                        prop.value[0] == '' &&
                        prop.options[0].id == ''
                          ? prop.options[0].description
                          : !prop['multi-select'] &&
                            prop.value == '' &&
                            prop.options[0].id == ''
                          ? prop.options[0].description
                          : ''
                      }}"
                      *ngSwitchCase="'select'"
                      [(value)]="prop.value"
                      multiple="{{ prop['multi-select'] }}"
                      [formControlName]="prop.fieldName"
                    >
                      <ng-container *ngFor="let list of prop.options">
                        <mat-option [value]="list.id" *ngIf="list.id != ''">
                          {{ list.description }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </div>
                </ng-container>
              </div>
            </div>
            <br />
          </form>

          <div
            class="row h-100"
            *ngIf="
              selectedOption != 'instructions' &&
              selectedOption != 'checklist' &&
              attachments
            "
          >
            <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
              <div class="loader" *ngIf="isLoading"></div>
              <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe>
            </ng-container>
            <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
              <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

              </ngx-extended-pdf-viewer> -->
              <div class="loader"></div>
              <!-- <iframe
                (load)="loadingPDF()"
                *ngIf="src"
                [src]="src"
                class="iframe"
                frameborder="0"
              ></iframe> -->
              <div class="error-message" style="display: none;">
                Unable to load the requested content. Please try again later.
            </div>
              <iframe  class="iframeTou" frameborder="0"></iframe>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #instructionanddocuments let-modal>
  <div class="instructions-popup">
    <div class="modal-header">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <h2 class="modal-title" style="color: #24597a">
          {{ "HOME.INSTRUCTDOCUMENT" | translate }}
        </h2>
        <div
          class="form-group"
          *ngIf="showInput"
          style="width: 42%"
          (keydown)="handleKeyDown($event)"
        >
          <input
            type="text"
            [(ngModel)]="inputText"
            id="inputvalue"
            (click)="diactivatedFlag = false"
            class="form-control"
            autofocus
          />
        </div>
        <div class="header-actions">
          <ng-container *ngIf="!isShoww">
            <button
              type="button"
              *ngIf="customizing?.triggerPrint != null"
              class="btn prints-btn"
              (click)="
                postPrintDetails('0',false); diactivatedFlag = true; hideForUrl()
              "
            >
              {{ "HOME.PRINTBUTTON" | translate }}
            </button>
            <button
            [disabled]="+customizing?.allow_cancelChecklist === 0"
              type="button"
              class="btn back-btn"
              (click)="modal.dismiss('Cross click'); resetForm()"
            >
              {{ "HOME.CANCELBUTTON" | translate }}
            </button>
            <button
              type="button"
              class="btn print-btn"
              #submitButton
              (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
            >
              {{ "HOME.CONFIRMBUTTON" | translate }}
            </button>
            <button
            [disabled]="+customizing?.allow_cancelChecklist === 0"
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            ></button>
          </ng-container>
          <ng-container *ngIf="isShoww">
            <button
              type="button"
              *ngIf="customizing?.triggerPrint != null"
              class="btn prints-btn"
              (click)="
                postPrintDetails('0',false); diactivatedFlag = true; hideForUrl()
              "
            >
              {{ "HOME.PRINTBUTTON" | translate }}
            </button>
            <button
            [disabled]="+customizing?.allow_cancelChecklist === 0"
              type="button"
              class="btn back-btn"
              (click)="modal.dismiss('Cross click'); resetForm()"
            >
              {{ "HOME.CANCELBUTTON" | translate }}
            </button>
            <button
              type="button"
              class="btn print-btn"
              [ngClass]="{ 'disabled-btn disabled': true }"
              #submitButton
              (click)="addChecklist('',submitButton); modal.dismiss('Cross click')"
            >
              {{ "HOME.CONFIRMBUTTON" | translate }}
            </button>
            <button
            [disabled]="+customizing?.allow_cancelChecklist === 0"
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            ></button>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="modal-body" (click)="this.diactivatedFlag = false">
      <div class="instructions-content">
        <div class="instructions-content-left">
          <ul>
            <li
              *ngIf="
                customizing?.showInstructions != null ||
                customizing?.showInstructions == goodsRecipt ||
                  goodsRecipt == '' ||
                true
              "
            >
              <a
                [ngClass]="isShoww ? 'chacive' : ''"
                class="checklisinstruction bold"
                (click)="
                  setOption('instructions', '');
                  isShoww = true;
                  isD = true;
                  diactivatedFlag = false;
                  inputText = ''
                "
                >{{ "HOME.INSTRUCTIONS_LABEL" | translate }}</a
              >
            </li>
            <li>
              <a
                class="checklisinstruction bold"
                [ngClass]="!isShoww ? 'chacive' : ''"
                (click)="
                  setOption('checklist', '');
                  isShoww = false;
                  isD = true;
                  this.secondOrderId = ''
                "
                >{{ "HOME.CHECKLIST_LABEL" | translate }}</a
              >
            </li>

            <ng-container *ngIf="!this.pdfFlag">
              <li *ngFor="let items of attachments">
                <a
                  [ngClass]="{
                    bold: selectedOption == items.document_type
                  }"
                  (click)="
                    setOption(items.document_type, items);
                    isD = false;
                    isShoww = false
                  "
                  >{{ items.document_type }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>

        <div class="projectbody instructions-content-right-desktop">
          <ng-container *ngIf="!isShoww">
            <form
              *ngIf="isD"
              novalidate
              [formGroup]="checkListFormGroup"
            >
              <div *ngFor="let prop of checkListItems">
                <div class="row mt-3">
                  <label
                    [attr.for]="prop.fieldName"
                    class="col-sm-4 d-flex p-2"
                    style="color: #24597a"
                    >{{ prop.fieldName }}</label
                  >

                  <ng-container [ngSwitch]="prop.fieldType">
                    <div class="col-sm-8">
                      <input
                        type="text"
                        hidden="true"
                        formControlName="details"
                      />
                      <textarea
                        *ngSwitchCase="'textarea'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        [ngClass]="{ textarea: prop.textarea }"
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        name=""
                        id=""
                        cols="1"
                        [rows]="prop.addrows"
                        (select)="toSelectUrl($event)"
                      ></textarea>
                      <input
                        *ngSwitchCase="'text'"
                        (select)="toSelectUrl($event)"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        [type]="prop.fieldType"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'int'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        appMinmax
                        [id]="prop.fieldName"
                        [min]="prop.min !== undefined ? prop.min : 0"
                        [max]="prop.max !== undefined ? prop.max : ''"
                        appMinmax
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        (select)="toSelectUrl($event)"
                      />

                      <input
                        *ngSwitchCase="'float'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        class="form-control"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="number"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        (select)="toSelectUrl($event)"
                      />

                      <input
                        *ngSwitchCase="'string'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        class="form-control"
                        [formControlName]="prop.fieldName"
                        [id]="prop.fieldName"
                        type="varchar"
                        [value]="prop?.value"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                        (select)="toSelectUrl($event)"
                      />

                      <input
                        *ngSwitchCase="'file'"
                        type="file"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                        (select)="toSelectUrl($event)"
                      />
                      <input
                        *ngSwitchCase="'checkbox'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        [ngClass]="[
                          prop.protected === true ? 'active' : 'in-active'
                        ]"
                        class="form-check-input"
                        [checked]="prop.value"
                        type="checkbox"
                        [id]="prop.fieldName"
                        [formControlName]="prop.fieldName"
                        (select)="toSelectUrl($event)"
                      />

                      <input
                        *ngSwitchCase="'email'"
                        type="email"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        (select)="toSelectUrl($event)"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <div class="input-group" *ngSwitchCase="'date'">
                        <input
                          class="form-control dateicon"
                          matInput
                          (click)="
                            diactivatedFlag = true;
                            $event.stopPropagation();
                            hideForUrl()
                          "
                          (select)="toSelectUrl($event)"
                          [matDatepicker]="dp"
                          (click)="dp.open()"
                          [id]="prop.key"
                          [formControlName]="prop.fieldName"
                        />
                        <mat-datepicker-toggle
                          class="date"
                          matSuffix
                          [for]="dp"
                          id="datepicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                      </div>

                      <input
                        *ngSwitchCase="'datetime'"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        (select)="toSelectUrl($event)"
                        type="datetime"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                      />

                      <input
                        *ngSwitchCase="'reset'"
                        type="reset"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        (select)="toSelectUrl($event)"
                        [id]="prop.fieldName"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'tel'"
                        type="tel"
                        [id]="prop.fieldName"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        (select)="toSelectUrl($event)"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <input
                        *ngSwitchCase="'url'"
                        type="url"
                        [id]="prop.fieldName"
                        (click)="
                          diactivatedFlag = true;
                          $event.stopPropagation();
                          hideForUrl()
                        "
                        (select)="toSelectUrl($event)"
                        [value]="prop?.value"
                        [formControlName]="prop.fieldName"
                        [placeholder]="
                          prop?.placeholder ? prop?.placeholder : ''
                        "
                      />

                      <mat-select
                        class="form-control"
                        placeholder="{{
                          prop['multi-select'] &&
                          prop.value[0] == '' &&
                          prop.options[0].id == ''
                            ? prop.options[0].description
                            : !prop['multi-select'] &&
                              prop.value == '' &&
                              prop.options[0].id == ''
                            ? prop.options[0].description
                            : ''
                        }}"
                        *ngSwitchCase="'select'"
                        [(value)]="prop.value"
                        multiple="{{ prop['multi-select'] }}"
                        [formControlName]="prop.fieldName"
                      >
                        <ng-container *ngFor="let list of prop.options">
                          <mat-option [value]="list.id" *ngIf="list.id != ''">
                            {{ list.description }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
              <br />
            </form>
          </ng-container>

          <ng-container *ngIf="!isD">
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>

              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-doc-viewer [url]="src" viewer="google">
                </ngx-doc-viewer> -->
                <!-- <a [href]="src" target="_blank">Open pdf</a> -->
                <!-- <iframe (load)="loadingPDF()" class="iframeTou" frameborder="0"></iframe> -->

                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

                </ngx-extended-pdf-viewer> -->
            <div class="loader"></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="isShoww">
              <div class="row mt-3" *ngFor="let item of instructionsItems">
                <label [ngClass]="getClass(layoutValues[0])" class="instLabel">{{
                  item?.fieldDescription
                }}</label>
                <label
                [ngClass]="getClass(layoutValues[1])"
                  class="instLabel"
                  *ngIf="item.fieldType == 'checkbox' && item?.value == null"
                  ><input class="form-check-input" type="checkbox" disabled
                /></label>
                <label
                [ngClass]="getClass(layoutValues[0])"
                  class="instLabel"
                  *ngIf="item.fieldType == 'text' && item?.value == null"
                ></label>
                <label
                  class="col-sm-4 instLabel"
                  *ngIf="item.fieldType == 'checkbox' && item?.value != null"
                  ><input
                    class="form-check-input"
                    type="checkbox"
                    checked
                    disabled
                /></label>
                <label
                [ngClass]="getClass(layoutValues[1])"
                  class="instLabel value-container"
                  *ngIf="item.fieldType == 'text' && item?.value != null"
                  >{{ item?.value }}</label
                >
              </div>
            
            <div
              class="row h-100"
              *ngIf="
                selectedOption != 'instructions' &&
                selectedOption != 'checklist' &&
                attachments
              "
            >
              <ng-container *ngIf="this.deviceInfo.deviceType != 'mobile'">
                <div class="loader" *ngIf="isLoading"></div>
                <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="this.deviceInfo.deviceType == 'mobile'">
                <!-- <ngx-extended-pdf-viewer [src]="src" [useBrowserLocale]="true">

      </ngx-extended-pdf-viewer> -->
      <div class="loader" ></div>
                <!-- <iframe
                  (load)="loadingPDF()"
                  *ngIf="src"
                  [src]="src"
                  class="iframe"
                  frameborder="0"
                ></iframe> -->
                <div class="error-message" style="display: none;">
                  Unable to load the requested content. Please try again later.
              </div>
                <iframe  class="iframeTou" frameborder="0"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Modal-->
<ng-template #content let-modal>
  <div class="modal-body Order-line-popup">
    <form [formGroup]="productForm" novalidate>
      <div
        class="Order-line-block d-flex justify-content-between align-items-center"
      >
        <label>{{ "HOME.KIND_LABEL" | translate }}</label>
        <div class="input-group">
          <select
            class="form-control"
            [(ngModel)]="defaultValue"
            formControlName="types"
            name=""
            id=""
          >
            <option
              value="{{ list?.processStep }}"
              *ngFor="let list of getPostValues?.types"
            >
              {{ list?.description }}
            </option>
          </select>
        </div>
      </div>
      <div
        *ngIf="this.getPostValues?.quantity"
        class="Order-line-block d-flex justify-content-between align-items-center"
      >
        <label>{{ "HOME.QUANTITY_LABEL" | translate }}</label>
        <div class="input-group">
          <input
            type="number"
            (select)="toSelectUrl($event)"
            (click)="diactivatedFlag = true; hideForUrl()"
            class="form-control order-input"
            min="1"
            formControlName="quantity"
          />
        </div>
      </div>
      <!-- <div class="Order-line-block d-flex justify-content-between align-items-center">
        <label>{{ "HOME.DEFAULT_QUANTITY_LABEL" | translate }}</label>
        <div class="input-group">
          <input type="number" class="form-control order-input" min="1" formControlName="default_quantity" >
        </div>
      </div> -->

      <div
        class="d-flex justify-content-center align-items-center popup-actions"
      >
        <button class="btn next-button" (click)="postPrintDetails('1',true)">
          {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #extrainputmodal let-modal>
  <div class="modal-header">
    <button
    [disabled]="+customizing?.allow_cancelChecklist === 0"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body Order-line-popup">
    <form
    (keydown.enter)="addChecklist('small',submitButton)"
      novalidate
      [formGroup]="checkListFormGroup"
      *ngIf="selectedOption == 'checklist'"
    >
      <div *ngFor="let prop of checkListItems">
        <div class="row mt-3">
          <label
            [attr.for]="prop.fieldName"
            class="col-sm-4 d-flex p-2"
            style="color: #24597a"
            >{{ prop.fieldName }}</label
          >

          <ng-container [ngSwitch]="prop.fieldType">
            <div class="col-sm-8">
              <input type="text" hidden="true" formControlName="details" />

              <input
                [ngClass]="errorMsg ? 'errormsg' : ''"
                *ngSwitchCase="'text'"
                class="form-control"
                [name]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                [formControlName]="prop.fieldName"
                [id]="prop.fieldName"
                [type]="prop.fieldType"
                [value]="prop?.value"
              />

              <input
                *ngSwitchCase="'int'"
                class="form-control"
                pattern="[0-9]*"
                inputmode="numeric"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                [name]="prop.fieldName"
                [formControlName]="prop.fieldName"
                appMinmax
                [min]="prop.min !== undefined ? prop.min : 0"
                [max]="prop.max !== undefined ? prop.max : ''"
                [id]="prop.fieldName"
                type="number"
                [value]="prop?.value"
              />

              <input
                *ngSwitchCase="'string'"
                class="form-control"
                [name]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
                [formControlName]="prop.fieldName"
                [id]="prop.fieldName"
                type="varchar"
                [value]="prop?.value"
              />

              <input
                *ngSwitchCase="'file'"
                type="file"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [formControlName]="prop.fieldName"
              />
              <input
                *ngSwitchCase="'checkbox'"
                [ngClass]="[
                  prop.protected === true ||
                  prop.protected == 'true' ||
                  +prop.protected === 1
                    ? 'active'
                    : 'in-active'
                ]"
                class="form-check-input"
                [name]="prop.fieldName"
                [checked]="prop.value"
                type="checkbox"
                [id]="prop.fieldName"
                [formControlName]="prop.fieldName"
              />

              <input
                *ngSwitchCase="'email'"
                type="email"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [value]="prop?.value"
                [formControlName]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
              />

              <div class="input-group" *ngSwitchCase="'date'">
                <input
                  class="form-control dateicon"
                  matInput
                  [matDatepicker]="dp"
                  [id]="prop.key"
                  [name]="prop.fieldName"
                  [formControlName]="prop.fieldName"
                />
                <mat-datepicker-toggle
                  class="date"
                  matSuffix
                  [for]="dp"
                  id="datepicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </div>

              <input
                *ngSwitchCase="'datetime'"
                type="datetime"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [value]="prop?.value"
                [formControlName]="prop.fieldName"
              />

              <input
                *ngSwitchCase="'reset'"
                type="reset"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [value]="prop?.value"
                [formControlName]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
              />

              <input
                *ngSwitchCase="'tel'"
                type="tel"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [value]="prop?.value"
                [formControlName]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
              />

              <input
                *ngSwitchCase="'url'"
                type="url"
                [id]="prop.fieldName"
                [name]="prop.fieldName"
                [value]="prop?.value"
                [formControlName]="prop.fieldName"
                [placeholder]="prop?.placeholder ? prop?.placeholder : ''"
              />
            </div>
          </ng-container>
        </div>
      </div>
      <br />
    </form>
  </div>
  <div class="modal-footer">
    <button
    [disabled]="+customizing?.allow_cancelChecklist === 0"
      type="button"
      class="btn btn-outline-dark cancelbtn"
      (click)="modal.close('Save click')"
    >
      {{ "HOME.CANCELBUTTON" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      #submitButton
      (click)="addChecklist('small',submitButton)"
    >
      {{ "HOME.CONFIRMBUTTON" | translate }}
    </button>
  </div>
</ng-template>
