import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/dataservice.service';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { environment } from '../../environments/environment';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { ChecklistModalComponent } from '../checklist-modal/checklist-modal.component';
import { Checklist } from '../../models/checklist';

@Component({
  selector: 'app-working-on',
  templateUrl: './working-on.component.html',
  styleUrls: ['./working-on.component.scss']
})

export class WorkingOnComponent implements OnInit {
  @ViewChild("textboxmodal") private contentRef: TemplateRef<Object>;
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  @ViewChild("content") private contentModal: TemplateRef<Object>;
  categories: any;
  green_eventdata: any;
  activityForm: any;
  closeResult: string;
  name: string | null;
  role: string | null;
  sorted_categories: any = [];
  customerID: string | null;
  dropdownform: any;
  dropdown_values: any = [];
  data: any;
  eventDesc: any;
  event: any;
  content: any;
  selected_element: string;
  checkListItems: any;
  current_user: any;
  checklist_flag: any;
  checkListFormGroup: FormGroup<any>;
  active_order: any;
  popupform: any;
  splitted_items: any = [];
  placeholder: any = [];
  values_b: any = [];
  SelectedCategory: any;
  constructor(private ChecklistModalComponent: ChecklistModalComponent, private config: NgbModalConfig, private modalService: NgbModal,
    public fb: UntypedFormBuilder, private toastrService: ToastrService, private apiservice: ApiService, private router: Router, private DataService: DataService, public LandingPageComponent: LandingPageComponent, public DashboardComponent: DashboardComponent) {
    this.categories = localStorage.getItem("greencategories");
    this.name = localStorage.getItem('smartbox_name');
    this.role = localStorage.getItem('role')
    this.categories = JSON.parse(this.categories);
    this.customerID = localStorage.getItem('customerID');
    this.current_user = localStorage.getItem("current_user");
    this.checklist_flag = localStorage.getItem("showchecklist");
    (this.current_user) ? this.current_user = JSON.parse(this.current_user) : this.current_user = undefined;
    this.activityForm = this.fb.group({
      event: ['', [Validators.required]],
    });
    this.dropdownform = this.fb.group({
      additional_info: ['', [Validators.required]],
    });
    this.popupform = this.fb.group({
      mail: ['', []],
    })
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.sorted_categories;
    let temp_array = [];
    for (let item in this.categories) {
      // if (this.categories[item].prefix && this.categories[item].prefix != '') {
      temp_array.push(this.categories[item].prefix)
      // }
    }
    temp_array = [...new Set(temp_array)];
    for (let item in temp_array) {
      let temp = [];
      for (let data in this.categories) {
        if (this.categories[data].prefix == temp_array[item]) {
          temp.push(this.categories[data])
        }
      }
      this.sorted_categories.push({ group: temp_array[item], cat: temp })
    }
  }

  ngOnInit(): void {
    this.DataService.sendValue(false);
  }

  async submit_dropdown() {
    if (this.dropdownform.valid) {
      let order;
      let active_order;
      if (this.role == "office") {
        order = this.LandingPageComponent.orders;
        active_order = this.LandingPageComponent.active_order;
      } else {
        order = this.DashboardComponent.orders;
        active_order = this.DashboardComponent.active_order;
      }
      for (let j = 0; j <= order.length - 1; j++) {
        let flag;
        if (
          order[j].eventType == 'green' &&
          order.length > 1 &&
          active_order.orderID != order[j].orderID
        ) {
          this.green_eventdata = order[j];
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      await this.setsFlag(this.data, this.eventDesc, this.event, this.content);
      await this.PostFormFields(this.data, this.eventDesc, this.event, this.content)
    }
  }

  async PostFormFields(data: any, eventDesc: any, event: any, content: any) {
    if (this.customerID == '3' && this.dropdownform.valid) {
      if (this.dropdownform.value.additional_info == 'Afwijkend' && !this.green_eventdata) {
        this.modalService.dismissAll();
        (this.role == 'office') ? this.LandingPageComponent.set_flag('blue', '') : (this.LandingPageComponent.set_flag('blue', ''));
        this.DataService.sendValue(true);
      }
      let order = (this.role == 'office') ? this.LandingPageComponent.active_order : this.DashboardComponent.active_order;
      let data = {
        eventID: (order as any).eventID,
        updateFields: [
          { Fieldname: this.dropdownform.value.additional_info }]
      };
      localStorage.setItem("green_formfields", JSON.stringify(data));
      if (!this.green_eventdata) {
        if (this.dropdownform.value.additional_info != 'Afwijkend') {
          this.modalService.dismissAll();
        }
        this.LandingPageComponent.Post_formFields(data);
      }

    }
  }


  async setsFlag(data: any, eventDesc: any, event: any, content: any) {
    this.config.backdrop = true;
    this.config.keyboard = true;
    if (this.role == 'office') {
      if (this.customerID != '3') {
        this.navigate();
      }
      if (this.green_eventdata) {
        localStorage.setItem(
          'green_eventdata',
          JSON.stringify(this.green_eventdata)
        );
        //let item = localStorage.getItem("green_eventdata");
        localStorage.setItem("green_subcat", JSON.stringify(eventDesc));
        this.open(content);
      } else {
        this.LandingPageComponent.current_screen = 'status_screen';
        if (this.customerID == '3') {
          this.navigate();
        }
        let greendata = localStorage.getItem("green_eventdata");
        greendata = JSON.parse(greendata as any);
        let data: any = localStorage.getItem("active_order")
        data = JSON.parse(data as any);
        this.active_order = { orderID: data.orderID };
        if (data && !greendata) {
          (greendata) ? (await this.LandingPageComponent.stop(greendata, 'yellow', false)) : '';
          (data) ? (await this.LandingPageComponent.stop(data, 'yellow', false)) : '';
          this.LandingPageComponent.order_viewdisable = true;
          let item = await this.LandingPageComponent.createorder('green', eventDesc, data);
          // (this.checklist_flag == 'afterGreen') ?
          //   this.getChecklistItems(this.current_user?.userID, data?.orderID, this.checklist_flag) : '';
          (greendata) ? await this.LandingPageComponent.createorder("green", 'working on the order', greendata) : '';
          setTimeout(() => {
            let greenID = this.LandingPageComponent?.GreenEventID;
            (greenID && (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '')) ? this.Post_FormFields(greenID) : this.navigate();
          }, 5000);
        } else {
        }
      }
    } else {
      if (environment.Deactivate_timer == true) {
        if (this.green_eventdata && !this.DashboardComponent.customizing.allow_parallel_green) {
          localStorage.setItem(
            'green_eventdata',
            JSON.stringify(this.green_eventdata)
          );
          localStorage.setItem("green_subcat", JSON.stringify(eventDesc));
          this.open(content);
        } else {
          let data: any = localStorage.getItem("active_order");
          data = JSON.parse(data as any);

          this.active_order = { orderID: data.orderID };
          let greendata = localStorage.getItem("green_eventdata");
          greendata = JSON.parse(greendata as any);
          (greendata) ? this.DashboardComponent.stop(greendata, 'green', false, '') : '';
          await this.DashboardComponent.stop(data, 'green', false, '');
          this.DashboardComponent.order_viewdisable = true;
          await this.DashboardComponent.createOrder('green', eventDesc, data, '');
          // (this.checklist_flag == 'afterGreen') ?
          //   this.ChecklistModalComponent.getChecklistItems(this.current_user?.userID, data?.orderID, this.checklist_flag) : '';
          (greendata) ? this.DashboardComponent.createOrder("green", 'working on the order', greendata, '') : '';
          localStorage.removeItem("green_eventdata");
          setTimeout(() => {
            let greenID = this.DashboardComponent?.GreenEventID;
            (greenID && (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '')) ? this.Post_FormFields(greenID) : this.navigate();
          }, 5000);
        }
      }
      if (this.customerID != '3') {
        this.navigate();
      }
    }
  }

  async Post_FormFields(YellowEventID: any) {
    let data: any = {
      eventID: YellowEventID,
      updateFields: [],
    };

    for (const [key, value] of Object.entries(this.popupform.value)) {
      if ((value as any).trim() != '') {
        data.updateFields.push({ [key]: value })
      }
    }
    if (data.updateFields?.length > 0) {
      this.apiservice.addformfield(data).subscribe(
        (res: any) => {
          this.popupform.reset({});
          if (res.response == 'success') {
          }
        }, (error) => {
        })
    }
  }

  async setFormFields(item: any) {
    this.SelectedCategory = item;
    if (this.SelectedCategory.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|')) {
        let temp = item.extraLevel.split('|');
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
          }
          if (!element.includes(";")) {
            this.splitted_items = element.split(" ");
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {
        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: []
          })
        }
      }

      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      if (this.customerID != '3') {
        this.open(this.popupmodal);
      } else {
        this.open(this.popupmodal);
        //this.setflag('',item,this.contentModal);
      }

    }
    if (item.extraLevel == null || item.extraLevel == '') {
      this.setflag('', item, this.contentModal);
    }
    this.DashboardComponent.toggleDowntime(false)
  }

  async submitdropdown() {
    if (this.popupform.valid) {
      this.modalService.dismissAll();
      this.setflag('', this.SelectedCategory, this.contentModal);
    }
  }



  setflag(data: any, eventDesc: any, content: any) {
    //event.target.disabled = true;
    // this.data = data;
    this.eventDesc = eventDesc;
    // this.event = event;
    // this.content = content;
    let order = (this.role != 'office') ? this.DashboardComponent?.orders : this.LandingPageComponent?.orders;
    let active_order = (this.role != 'office') ? this.DashboardComponent?.active_order : this.LandingPageComponent?.active_order;
    for (let j = 0; j <= order.length - 1; j++) {
      let flag;
      if (
        order[j].eventType == 'green' &&
        order.length > 1 &&
        active_order.orderID != order[j].orderID
      ) {
        this.green_eventdata = order[j];
        flag = true;
        break;
      } else {
        flag = false;
      }
    }
    if (this.customerID == '3') {
      if (eventDesc.extraLevel != null || eventDesc.extraLevel == 'null' || eventDesc.extraLevel == '') {
        this.dropdown_values = eventDesc.extraLevel.split(';');
      }
      if (eventDesc.preventSkipExtraLevel == '1') {
        this.config.backdrop = 'static';
        this.config.keyboard = false;
      } else {
        this.config.backdrop = true;
        this.config.keyboard = true;
      }
      //this.open(this.contentRef);
      this.setsFlag(data, eventDesc, event, content);
    } else {
      // let order = (this.role != 'office') ? this.DashboardComponent?.orders : this.LandingPageComponent?.orders;
      // let active_order = (this.role != 'office') ? this.DashboardComponent?.active_order : this.LandingPageComponent?.active_order;
      // for (let j = 0; j <= order.length - 1; j++) {
      //   let flag;
      //   if (
      //     order[j].eventType == 'green' &&
      //     order.length > 1 &&
      //     active_order.orderID != order[j].orderID
      //   ) {
      //     this.green_eventdata = order[j];
      //     flag = true;
      //     break;
      //   } else {
      //     flag = false;
      //   }
      // }
      this.setsFlag(data, eventDesc, event, content);
    }
  }

  clear_greendata() {
    localStorage.removeItem('green_eventdata');
    localStorage.removeItem("green_subcat");
    this.green_eventdata = undefined;
  }

  async onSubmit_event() {
    if (this.role == 'office') {
      this.LandingPageComponent.activityForm = this.activityForm;
      this.LandingPageComponent.green_eventdata = this.green_eventdata;
      await this.LandingPageComponent.onSubmit_event();
    } else {
      this.DashboardComponent.activityForm = this.activityForm;
      this.DashboardComponent.green_eventdata = this.green_eventdata;
      await this.DashboardComponent.onSubmit_event();
    }
    this.activityForm.reset({});
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  navigate() {
    (this.role === 'office') ? this.LandingPageComponent.set_flag('screen', '') : this.DashboardComponent.set_flag('screen', '');
  }
}
